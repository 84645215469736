import React, { Component } from "react";
import ReactDOM from "react-dom";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { SUPPLIERID_WHITE_LIST } from "@/config/const";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { transform, isFunction } from "./helper";
import userStore from "@/store/user";
import "./request.css";

// 创建 Axios 实例
const baseURL = process.env.REACT_APP_BASE_URL;

// 当前正在请求的数量
let requestCount = 0;

// 显示loading
function showLoading() {
  if (requestCount === 0) {
    var dom = document.createElement("div");
    dom.setAttribute("id", "globalLoading");
    document.body.appendChild(dom);
    ReactDOM.render(
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />,
      dom
    );
  }
  requestCount++;
}

// 隐藏loading
function hideLoading() {
  requestCount--;
  if (requestCount === 0) {
    const dom = document.getElementById("globalLoading");
    dom && document.body.removeChild(dom);
  }
}

const instance: AxiosInstance = axios.create({
  baseURL,
  timeout: 30000,
  headers: {
    // "client-channel-id": "f7rs2cg4arhi2sqqke1o3j5",
    "Content-Type": "application/json",
  },
});

// 添加请求拦截器
instance.interceptors.request.use(
  (config: AxiosRequestConfig | any) => {
    if (config.headers?.isLoading === "true") {
      showLoading();
    }
    config.headers["Authorization"] = `Thor ${userStore.getToken()}`;
    config.headers["platform"] = "pc";
    const supplierId = userStore.getSupplierId();
    let url = config.url;
    let position = url.indexOf("?");
    if (position > -1) {
      url = url.substring(0, position);
    }
    if (SUPPLIERID_WHITE_LIST.includes(url) && supplierId) {
      config.headers["target-tenant-id"] = supplierId;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.config.headers?.isLoading === "true") {
      hideLoading();
    }
    return response;
  },
  (error) => {
    if (error.config.headers?.isLoading === "true") {
      hideLoading();
    }
    return Promise.reject(error);
  }
);

export const request = <T = any,>(
  config: AxiosRequestConfig,
  options?: any
): Promise<T> => {
  return new Promise((resolve, reject) => {
    instance
      .request<any, AxiosResponse<any>>(config)
      .then((res: any) => {
        const { transformRequestHook } = transform;
        if (transformRequestHook && isFunction(transformRequestHook)) {
          let ret = transformRequestHook(res, options);
          if (ret) {
            resolve(ret);
          }
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export default instance;
