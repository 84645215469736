import { request } from "@/utils/request";
export function getGoodsDeatil(id: String) {
  return request({
    url: `/shelves/commodity?isSubjectProduct=true&id=${id}`,
    method: "GET",
    headers: {
      isLoading: true,
    },
  });
}
// 营销活动获取商品详情
export function getGoodsDeatilV2(params: any) {
  return request({
    url: `/shelves/v2/commodity?isSubjectProduct=true`,
    method: "GET",
    params,
    headers: {
      isLoading: true,
    },
  });
}
export function fetchGetStudyStat(data: any) {
  return request({
    url: `/shelves/commodity/getStudyStat`,
    method: "POST",
    data,
  });
}
// 获取商品活动信息
export function getActivityInfo(commodityId: String) {
  return new Promise<any>(async (resolve) => {
    let res = await request({
      url: `/util/activity/group?commodityId=${commodityId}`,
    });
    res = res ?? {};
    res.skuData = res?.skuData?.map((item: any) => {
      // 原价缓存 拼团用
      item.price1 = item.price;
      item.price = item.amount;
      // 无库存则默认10000件
      item.stock = Math.min(
        item.stock ?? 10000,
        item.overplus ?? 10000,
        item.quantity ?? 10000
      );
      return item;
    });
    resolve(res);
  });
}
// 获取优惠券信息
export function getCouponList(commodityId: String) {
  return request({
    url: `/util/coupon/list?commodityId=${commodityId}`,
    method: "GET",
  });
}
// 领取优惠劵 util/coupon/receive?id=ac000000085
export function receiveCoupon(id: String) {
  return request({
    url: `util/coupon/receive?id=${id}`,
    method: "POST",
  });
}

// 获取商品详情页的关注人数
export function getStudyFollowNumber(commodityId: String) {
  return request({
    url: `/shelves/commodity/getCommodityStudyStat?commodityId=${commodityId}`,
    method: "GET",
  });
}
// 获取授权和协议
export function getAuthorizeAndSign(params: any) {
  return request({
    url: `/shelves/my/v2/lesson/authorize`,
    method: "GET",
    params,
  });
}
// 获取学习单元详情
export function getUnitDetail(params: any) {
  return request({
    url: `/resource/lesson`,
    method: "GET",
    params,
  });
}

// 获取已购买学习信息
export function getUnitList(params: any) {
  return request({
    url: `/resource/lesson?flag=true`,
    method: "GET",
    params,
  });
}

// 获取直播回放
export function getReplayList(params: any) {
  return request({
    url: `/resource/lesson/live/replay`,
    method: "GET",
    params,
  });
}

export function getGoodsDeatils(id: string) {
  return request({
    url: `/shelves/commodity/?isSubjectProduct=true&id=${id}`,
    method: "GET",
  });
}

// 获取商品展示组件-分类信息
export function requestCommodityTypes(data: any) {
  return request({
    url: `/shelves/commodity/getCommodityComponent/types`,
    method: "POST",
    data,
  });
}
// 获取商品展示组件-产品树信息
export function requestProductTrees(data: any) {
  return request({
    url: `/shelves/commodity/getCommodityComponent/data`,
    method: "POST",
    data,
  });
}

//根据skuId获取商品同级使用期限
export function getGoodsUseLimit(skuId: string) {
  return request({
    url: `/shelves/commodity/sku/term?skuId=${skuId}`,
    method: "GET",
  });
}

// 心跳
export function heartbeatApi(data: any) {
  return request({
    url: "/resource/video/heartbeat",
    method: "POST",
    data,
  });
}

// 根据产品id获取课程数据
export function getMyLessons(params: any) {
  return request({
    url: `/shelves/my/lesson1`,
    method: "GET",
    params,
  });
}