import React, { useState, useEffect } from "react";

import { useDebounce } from "use-debounce";
import { NavigationType, useNavigate } from "react-router-dom";
import {
  Modal,
  Select,
  Dropdown,
  Space,
  Input,
  SelectProps,
  MenuProps,
} from "antd";
import { CHECK_AUTO_LOGIN, ORG_PREFIX, LOGO_URL } from "@/config/const";
import { DownOutlined } from "@ant-design/icons";
import userStore from "@/store/user";
import { searchCourseList } from "@/apis/course";
import { fetchChannelDetail } from "@/apis/user";
import Login from "@/components/Login"; // 登录
import QrcodeModal from "@/components/QrcodeModal"; // 登录
import "./css/header.scss";
function Header(props: any) {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(userStore.getUserInfo());
  const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false); // 手机H5学习或购买弹框
  const [data, setData] = useState<SelectProps["options"]>([]);
  const [searchValue, setSearchValue] = useState<string>(props.search || "");
  const [logoUrl, setLogoUrl] = useState(""); // logo
  const [debouncedFilter] = useDebounce(searchValue, 500);
  const { Search } = Input;
  const {
    backgroundColor,
    addNav = [],
    color,
    paddingStyle,
    isShow,
  } = props.data || {};
  const { paddingBottom, paddingLeft, paddingTop, paddingRight } =
    paddingStyle || {};
  const style = {
    backgroundColor: backgroundColor,
    color: color,
    paddingTop,
    paddingRight,
    paddingLeft,
    paddingBottom,
  };

  useEffect(() => {
    getSearchCourseResult({
      name: searchValue,
    });
  }, [debouncedFilter]);

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await fetchChannelDetail();
        if (response && response.navigationLogo) {
          localStorage.setItem(
            `${ORG_PREFIX}_${LOGO_URL}`,
            response.navigationLogo
          );
          setLogoUrl(response.navigationLogo);
        }
      } catch (error) {
        console.error("获取失败", error);
      }
    };
    const logo_url = localStorage.getItem(`${ORG_PREFIX}_${LOGO_URL}`);
    if (logo_url) {
      setLogoUrl(logo_url);
    } else {
      fetchLogo();
    }
  }, []);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // 点击跳转到首页
  function handleToHome() {
    navigate("/");
  }
  // 点击弹出手机学习的引导二维码
  function handlePhoneLearn() {
    setQrCodeModalOpen(true);
  }
  // 点击后打开登录弹窗
  function handelToLogin() {
    setIsModalOpen(true);
  }

  // 获取课程搜索结果
  const getSearchCourseResult = async (params: any) => {
    const courseInfo = await searchCourseList({
      commodityName: params.name,
      type: 1,
      status: 0,
      index: 0,
      row: 10,
    });
    setData(courseInfo || []);
  };
  // const userInfo = userStore.getUserInfo();

  useEffect(() => {
    setUserInfo(userStore.getUserInfo());
  }, [userStore.userInfo]);

  // 搜索跳到课程查询列表
  const handleSearch = (newValue: string) => {
    navigate(`/allCourse/search/${newValue}`);
  };

  // 退出
  const logout = () => {
    userStore.setToken("");
    localStorage.removeItem(`${CHECK_AUTO_LOGIN}`);
    userStore.setUserInfo({});
    navigate("/");
    window.location.reload();
  };

  const handleNavigate = (url: string) => {
    navigate(url);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <span
          rel="noopener noreferrer"
          onClick={() => handleNavigate("/personalCenter?topNavIndex=5")}
        >
          个人资料
        </span>
      ),
    },
    {
      key: "2",
      label: (
        <span
          rel="noopener noreferrer"
          onClick={() => {
            handleNavigate("/personalCenter?topNavIndex=3");
          }}
        >
          我的订单
        </span>
      ),
    },
    {
      key: "3",
      label: (
        <span
          rel="noopener noreferrer"
          onClick={() => {
            handleNavigate("/personalCenter?topNavIndex=0");
          }}
        >
          我的课程
        </span>
      ),
    },
    {
      key: "4",
      label: (
        <a onClick={logout} rel="noopener noreferrer" href="javascript:void(0)">
          退出登录
        </a>
      ),
    },
  ].filter((item, index) => {
    if (
      window.location.hash.indexOf("personalCenter") > -1 &&
      [1, 2, 3].includes(+item?.key)
    ) {
      // 个人中心不要展示个人资料和我的订单
      return false;
    } else {
      return true;
    }
  });
  const handleNav = (arr: any) => {
    let href = "/";
    const type = arr.link[0];

    if (arr.link.length === 0) return;
    if (arr.link.length === 2) {
      href = arr.link[1];
    }
    switch (type) {
      case "功能链接":
        gocenter(href);
        break;
      case "微页面":
        navigate(`/webPage/${href}`);
        break;
      case "超链接":
        goByLink(arr.linkhref);
        break;
    }
  };
  // 功能链接跳转函数
  const gocenter = (href: string) => {
    switch (href) {
      case "我的课程":
        navigate("/personalCenter?topNavIndex=0");
        break;
      case "课程激活":
        navigate("/personalCenter?topNavIndex=2");
        break;
      case "我的订单":
        navigate("/personalCenter?topNavIndex=3");
        break;
      case "我的协议":
        navigate("/personalCenter?topNavIndex=4");
        break;
    }
  };

  //超链接跳转函数
  const goByLink = (hyperLink: string) => {
    window.location.href = hyperLink;
  };
  //处理下拉框的函数
  const formatData = (data: any) => {
    const arr = data.map((item: any, index: any) => {
      return {
        key: item.name + index,
        label: <a onClick={() => handleNav(item)}>{item.name}</a>,
      };
    });

    return arr;
  };

  // 关闭弹框
  const handleCloseQrCode = (val: boolean) => {
    setQrCodeModalOpen(val);
  };

  return (
    <div className="header-outwrap-top" style={style}>
      <div className="header-content">
        <div className="search-wrap">
          <img
            className="header-logo"
            src={logoUrl}
            alt=""
            onClick={handleToHome}
          />
          <div className="header-text" onClick={handleToHome}></div>
        </div>
        {addNav.map((item: any) => {
          return (
            <div className="header-navgation">
              {item.child.length > 0 ? (
                <Dropdown menu={{ items: formatData(item.child) }}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Space onClick={() => handleNav(item)}>{item?.name}</Space>
                  </a>
                </Dropdown>
              ) : (
                <div onClick={() => handleNav(item)}>{item?.name}</div>
              )}
            </div>
          );
        })}
        {isShow && (
          <Search
            placeholder="搜索课程"
            allowClear
            onSearch={handleSearch}
            className="header-input"
          />
        )}
        <div className="header-right fr">
          <div className="header-phone" onClick={handlePhoneLearn}>
            <span className="iconfont icon-phone"></span>
            <div className="phone-text1">用手机学</div>
          </div>
          {userStore.getToken() ? (
            <div className="header-adylogin">
              <Dropdown menu={{ items }} className="menu-dropdown">
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    <div className="header-user">
                      <img
                        src={userInfo.avatar}
                        alt="avatar"
                        className="user-avatar"
                      />
                      <div className="user-name">{userInfo.nickname}</div>
                    </div>
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </div>
          ) : (
            <div className="header-login" onClick={handelToLogin}>
              <div className="login-item">
                <span className="iconfont icon-user"></span>
                <div className="login-btn">登录</div>
              </div>
              <div>|</div>
              <div className="register-btn">注册</div>
            </div>
          )}
        </div>
      </div>
      <Modal
        title=""
        className="header-modal-wrap"
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Login />
      </Modal>
      <QrcodeModal
        isShow={qrCodeModalOpen}
        type={"study"}
        onCloseQrCode={handleCloseQrCode}
      />
    </div>
  );
}

export default Header;
