import React, { useState, useEffect, useRef } from "react";
import { Modal, Input, Select, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "../css/PersonalMessage.scss";
import {
  fetchUserInfo,
  editUserCompany,
  editUserInfo,
  getEnterpriseList,
  upload,
} from "@/apis/user";
import userStore from "@/store/user";
const { Option } = Select;

function PersonalMessage() {
  const [messageData, setMessageData] = useState({
    avatar: "",
    nickname: "",
    workUnit: "",
    org: "",
  });
  const [newNickname, setNewNickname] = useState("");
  const [newWorkUnit, setNewWorkUnit] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUnitModalVisible, setIsUnitModalVisible] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState(""); // 用于存储搜索关键词
  const [options, setOptions] = useState<OptionType[]>([]); // 用于存储从接口获取的选项数据
  const [page, setPage] = useState(1);//单位选项页数
  const [modals, setModals] = useState(1); //1是编辑名称，2是编辑单位
  //  判断json 字符串
  const isJSON = (str: string) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };
  // 获取用户信息
  const getUserInfo = async () => {
    const result = await fetchUserInfo();
    console.log("个人中心getUserInfo--", result);
    if (isJSON(result.org)) {
      const infoOrg = JSON.parse(result.org);
      setMessageData({
        avatar: result.avatar,
        nickname: result.nickname,
        workUnit: result.workUnit,
        org: infoOrg.org,
      });
    } else {
      setMessageData(result);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files ? event.target.files[0] : "";
    if (file) {
      const res = await upload({
        file: file,
        " Content-Type": "multipart/form-data;charset=UTF-8",
      });
      if (res) {
        const upImg = await editUserInfo({
          avatar: res,
          nickname: messageData.nickname,
          gender: 0,
          org: messageData.org,
        });
        if (upImg) {
          const info = Object.assign(
            { ...userStore.getUserInfo() },
            { avatar: res }
          );
          userStore.setUserInfo(info)
          await getUserInfo();
        }
      }
    }
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


  // 打开编辑昵称还是单位
  const showModal = (x: number) => {
    if (x === 1) {
      if (!isModalVisible) setIsModalVisible(true);
      setModals(1);
      setNewNickname(messageData.nickname);
    } else {
      if (!isUnitModalVisible) setIsUnitModalVisible(true);
      setModals(2);
      setNewWorkUnit(messageData.workUnit);
    }
  };

  // 更新昵称或单位
  const handleOk = async () => {
    if (modals === 1) {
      const res = await editUserInfo({
        avatar: messageData.avatar,
        nickname: newNickname,
        gender: 0,
        org: messageData.org,
      });
      if (res) {
        const info = Object.assign(
          { ...userStore.getUserInfo() },
          { nickname: newNickname }
        );
        userStore.setUserInfo(info)
        await getUserInfo();
      }
      setIsModalVisible(false);
      setNewNickname("");
    } else {
      const res = await editUserInfo({
        avatar: messageData.avatar,
        nickname: messageData.nickname,
        gender: 0,
        org: newWorkUnit,
      });
      if (res) {
        const info = Object.assign(
          { ...userStore.getUserInfo() },
          { org: newWorkUnit }
        );
        userStore.setUserInfo(info)
        await getUserInfo();
      }
      setIsUnitModalVisible(false);
      setNewWorkUnit("");
    }
  };

  const handleCancel = () => {
    if (modals === 1) {
      setIsModalVisible(false);
    } else {
      setIsUnitModalVisible(false);
    }
  };
  interface OptionType {
    label: string;
    value: string;
  }

  const handleSearch = (value: React.SetStateAction<string>) => {
    setSearchKeyword(value);
  };
  // 点击搜索图标
  const handleSuffixIconClick = async () => {
    //发起请求获取Options
    const res = await getEnterpriseList({
      key: searchKeyword,
      index: page,
      row: 20,
    });
    const option: Array<OptionType> = [];
    res.forEach((item: string) => {
      let obj = { value: "", label: "" };
      obj.label = item;
      obj.value = item;
      option.push(obj);
    });
    setOptions([...option, ...options]);
    // console.log(options);
  };
  //
  const handlePopupScroll = (e: { target: any }) => {
    const { target } = e;
    if (target.scrollTop + target.clientHeight === target.scrollHeight) {
      setPage(page + 1);
      console.log(page);
      handleSuffixIconClick();
    }
  };
  return (
    <div className="personal-message-container">
      <div className="message-content">
        <div className="message-item">
          <div>头像</div>
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileInputChange}
          />
          <img
            src={messageData.avatar || require("../images/avatar.png")}
            alt="avatar"
            onClick={handleImageClick}
          />
        </div>
        <div className="message-item" onClick={() => showModal(1)}>
          <div>昵称</div>

          <div>{messageData.nickname || "无"}</div>
        </div>
        <div className="message-item" onClick={() => showModal(2)}>
          <div>工作单位</div>
          {messageData.org ? <div>{messageData.org}</div> : <div>无</div>}
        </div>
      </div>
      <Modal
        title="编辑昵称"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input
          placeholder="请输入昵称"
          value={newNickname}
          onChange={(event) => setNewNickname(event.target.value)}
        />
      </Modal>
      <Modal
        title="编辑工作单位"
        open={isUnitModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Select
          showSearch
          style={{ width: "100%" }}
          placeholder="请选择工作单位"
          optionFilterProp="children"
          onSearch={handleSearch}
          value={newWorkUnit}
          onChange={(value) => setNewWorkUnit(value)}
          onPopupScroll={handlePopupScroll}
          suffixIcon={
            <SearchOutlined
              style={{
                fontSize: 16,
                color: "#1890ff",
                marginLeft: 5,
              }}
              onClick={handleSuffixIconClick}
            />
          }
        >
          {options
            ? options.map((option: OptionType, index) => (
                <Option key={index} value={option.value}>
                  {option.label}
                </Option>
              ))
            : ""}
        </Select>
      </Modal>
    </div>
  );
}

export default PersonalMessage;
