
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import QrcodeModal from "@/components/QrcodeModal"; // 登录
import { setPlayUnit } from "@/utils/storageFactory";
import "./css/courseListModal.scss";
// import { Course } from "@/pages/PersonalCenter/types";
// interface Iprops {
//   isShow: boolean; // 是否展示弹框
//   onCloseQrCode: Function; // 关闭弹框回调
//   courseList: Course; // 列表数据
// }
function CourseList(props: any) {
  const navigate = useNavigate();
  const { onCloseQrCode } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [courseList, setCourseList] = useState([]); // 课程列表数据
  const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false); // 手机H5学习或购买弹框
  const [commodityId, setCommodityId] = useState<string>(""); // 商品id
  useEffect(() => {
    setIsModalOpen(props.isShow);
    setCourseList(props.courseList);
    setCommodityId(props.commodityId);
  }, [props.isShow, props.courseList, props.commodityId]);

  const handleCancel = () => {
    onCloseQrCode(false);
  };
  const startStudy = (productId: string) => {
    console.log("开始学习");
    setPlayUnit('', '', '','');//清空当前的选中
    navigate(`/videoPlay?productId=${productId}&isPurchase=true&from=1`);
    // setQrCodeModalOpen(true);
  };
  // 关闭弹框
  const handleCloseQrCode = (val: boolean) => {
    setQrCodeModalOpen(val);
  };
  return (
    <div>
      <Modal
        title=""
        centered
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        wrapClassName={"qrcode-pop-wrap"}
        width={680}
      >
        <div className="course-list">
          {courseList &&
            courseList.map((item: any, index) => {
              return (
                <div className="course-list-card" key={index}>
                  <img
                    className="course-list-img"
                    alt="course-img"
                    src={require("./images/course-cover.png")}
                  />
                  <div className="course-list-info">
                    <div className="course-list-title">{item.v}</div>
                    <div className="course-list-use-time">{item.useTime}</div>
                    <button
                      className="course-list-button"
                      onClick={() => startStudy(item.k)}
                    >
                      开始学习
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      </Modal>
      <QrcodeModal
        isShow={qrCodeModalOpen}
        type={"study"}
        onCloseQrCode={handleCloseQrCode}
      />
    </div>
  );
}

export default CourseList;
