import React, { useState, useEffect } from "react";
import "./css/topFloat.scss";
import { fetchCustomer } from "@/apis/user";
import channelStore from "@/store/channel";

declare const window: any;
function TopFloat() {
  const [isHovered, setIsHovered] = useState(false);
  const [customerSet, setCustomerSet] = useState({
    switchType: 0, //引导联系客服
    entranceType: 1, //入口样式0-默认，1-自定义
    customerType: 1, //客服方式1-h5，2-js
    customerLink: "", //客服链接
    entranceImg: "", //入口图片
    customerCode: "", //js客服代码
  }); //是否开启在线客服

  const channelInfo = channelStore.getChannelInfo();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    // 获取客服配置
    getChartSet();
  }, []);

  const getChartSet = async () => {
    const res = await fetchCustomer();
    if (res) {
      setCustomerSet(res);
    }
    console.log(res);
  };
  const openChatModal = async () => {
    if (customerSet.customerType == 1) {
      window.open(customerSet.customerLink, "_blank");
      return;
    } else {
      chartCode();
    }
  };

  const stringToDOM = (str: string) => {
    if (str.includes("script")) {
      var parser = new DOMParser();
      // 使用DOMParser的parseFromString方法来转换字符串到DOM
      var doc = parser.parseFromString(str, "text/html");
      console.log(doc);
      // 获取转换后的DOM对象
      var dom: any = doc.body.firstChild || doc.head.firstChild;
      const script1: any = document.createElement("script");
      script1.src = dom.src;
      return script1;
    } else {
      const script1: any = document.createElement("script");
      script1.src = str;
      return script1;
    }
  };

  const chartCode = () => {
    if (window.mantis && window.mantis.requestChat) {
      window.mantis.requestChat();
    } else {
      console.log(customerSet.customerCode);
      const scriptDom = stringToDOM(customerSet.customerCode);
      document.head.appendChild(scriptDom);
      // 设置轮询,避免requestChat获取不到的情况
      let num = 0;
      let timerId = setInterval(() => {
        num++;
        // 轮询10秒没有初始化关闭
        if (num > 20) {
          clearInterval(timerId);
        }
        // 获取到requestChat
        if (window.mantis && window.mantis.requestChat) {
          window.mantis.requestChat();
          clearInterval(timerId);
        }
      }, 500);
    }
  };

  return (
    <div className="float-content">
      {isHovered && (
        <div className="float-phone">
          咨询电话：{channelInfo["telephone"] || "4000-188-000"}
        </div>
      )}
      <div className="float-list">
        <div
          className="float-item float-item-first"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <img src={require("./images/phone.png")} />
          <div>电话咨询</div>
        </div>
        {customerSet.switchType == 1 ? (
          <div className="float-item-second" onClick={openChatModal}>
            <img
              src={
                customerSet?.entranceType == 1
                  ? customerSet?.entranceImg
                  : require("./images/chat.png")
              }
            />
          </div>
        ) : null}
        <div className="float-item" onClick={scrollToTop}>
          <img src={require("./images/direction.png")} />
          <div>回到顶部</div>
        </div>
      </div>
    </div>
  );
}

export default TopFloat;
