import { useEffect, useState, memo } from "react";
import { useNavigate } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import { Modal } from "antd";
import { Tabs } from "antd";
import RecursionLevelList from "@/components/CourseTree/RecursionLevelList";
import {
  getGoodsDeatils,
  requestProductTrees,
  getGoodsUseLimit,
  fetchGetStudyStat,
  getActivityInfo,
} from "@/apis/courseDetail";
import { flatList, getProductIds } from "@/utils/tree";
import LearnInfoGoodsShow from "./LearnInfoGoodsShow";
import { setPlayUnit } from "@/utils/storageFactory";
import QrcodeModal from "../QrcodeModal";
import "./goodsShow.scss";
import 'antd/dist/reset.css';
// 商品展示-树组件
function RecursionTree({ params, config }: any) {
  const {
    styles = {},
    types = [],
    fields = {},
    showMore = false,
    showTitle = false,
    title = "",
  } = config; // 配置
  const navigate = useNavigate();
  const [productTrees, setProductTrees] = useState<any>([]);
  const [timeList, setTimeList] = useState<any>([]); //根据已选skuId获取对应使用期限
  const [currentType, setCurrentType] = useState<any>(params.unitType); // 当前选中分类
  const [progressInfo, setProgressInfo] = useState<any>({
    done: 0,
    num: 0,
    speedRate: 0,
  }); // 进度展示信息
  const [goodsInfo, setGoodsInfo] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);
  const [numPages, setNumPages] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // 学习弹框
  const [statics, setStatics] = useState<any[]>([]);

  // 获取产品树
  const getProductTrees = async (goodsInfo: any) => {
    if (Object.keys(goodsInfo).length > 0) {
      const {
        productTrees = [],
        done = 0,
        num = 0,
        speedRate = "",
      } = await requestProductTrees(goodsInfo);

      // 动态获取进度数据
      const productIds = getProductIds(productTrees, "P");
      const statics1 = await fetchGetStudyStat({ productIds });
      setStatics(statics1);
      const { commodityId = "" } = params;
      const trees = productTrees
        ? flatList(productTrees, 0, { commodityId })
        : [];
      setProgressInfo({
        done,
        num,
        speedRate,
      });
      setProductTrees(trees);
    }
  };


  // // 获取分类信息
  // const getCommodityTypes = async (params: any) => {
  //   const body = await requestCommodityTypes(params);
  //   setTypes(body);
  //   return body;
  // };

  useEffect(() => {
    getProductTrees(params);
  }, [params]);

  // 处理二维码弹框
  const handleCloseQrCode = () => {
    setIsModalOpen(false);
  };
  const onDocumentLoadSuccess = (e: any) => {
    setNumPages(e.numPages);
  };
  const handleCallback = async (item: any) => {
    const {
      type,
      id: unitId,
      name,
      commodityId,
      productId = "",
      preview = false,
      isPurchase,
      skuId = "",
      mode = 1,
    } = item;
    const goodsData = await getGoodsDeatils(commodityId);
    const ret = await getActivityInfo(commodityId);
    if (ret?.activityId) {
      goodsData.activityType = ret.type;
      if (ret?.activityStatus == 0) {
        goodsData.activityId = ret.activityId;
        goodsData.skuList = ret?.skuData;
      } else {
        goodsData.startTimeStr = ret.startTimeStr;
      }
      goodsData.activityStatus = ret?.activityStatus;
      goodsData.groupNum = ret?.number || 0;
      goodsData.inDate = ret.inDate;
      // setActivityId(ret?.activityId);
      // setAmount(ret?.amount);
      if (goodsData.activityType == 4) {
        goodsData.skuList.forEach((item: any) => {
          item.stock = 10000;
        });
      }
    }
    console.log(goodsData, "goodsData");
    setGoodsInfo(goodsData);
    if (preview || isPurchase) {
      if (type === 1 || type === 0 || type === 4) {
        // 视频和资源
        setPlayUnit("", productId, "", unitId);
        // navigate(
        //   `/videoPlay?commodityId=${goodsId}&isPurchase=${unit.isPurchase}`
        // );
        window.open(
          `${window.location.origin}/#/videoPlay?commodityId=${goodsData.id}&isPurchase=${isPurchase}`
        );
      } else {
        // setQrCodeUrl(
        //   `${window.location.origin}/#/page_commodity/commodity/pages/commodityBank?id=${goodsId}`
        // );
        setIsModalOpen(true);
      }
    } else {
      //判断是否有使用期限
      const timeLimit = goodsData?.skuOptions.filter(
        (item: any) => item.type === 1
      );
      if (skuId) {
        const result = goodsData?.skuList?.find(
          (sku: any) => +sku.skuId === +skuId
        );
        let currentSkuObj = result ? result : goodsData?.skuList[0];
        nabigateByMode(goodsData, mode, timeLimit, currentSkuObj);
      } else {
        nabigateByMode(goodsData, mode, timeLimit, goodsData?.skuList[0]);
      }
    }
  };
  const nabigateByMode = async (
    goodsData: any,
    mode: number,
    timeLimit: Array<any>,
    currentSkuObj: any
  ) => {
    if (timeLimit.length === 0) {
      mode === 2 && goodsData?.contract
        ? setOpen(true)
        : navigate("/orderSettlement", {
            state: {
              selectList: [
                {
                  ...currentSkuObj,
                  number: 1,
                  thumbnail: goodsData?.thumbnail,
                  name: goodsData?.name,
                  price: currentSkuObj.price,
                },
              ],
              id: goodsData.id,
              contract: goodsData?.contract || {},
              activityId: goodsData?.activityId || "",
            },
          });
    } else {
      let res = await getGoodsUseLimit(currentSkuObj?.skuId);
      setTimeList(res);
      mode === 2 && goodsData?.contract
        ? setOpen(true)
        : navigate("/orderSettlement", {
            state: {
              selectList: [
                {
                  ...currentSkuObj,
                  number: 1,
                  thumbnail: goodsData?.thumbnail,
                  name: goodsData?.name,
                  price: currentSkuObj.price,
                },
              ],
              id: goodsData.id,
              contract: goodsData?.contract || {},
              activityId: goodsData?.activityId || "",
              timeList: res,
            },
          });
    }
  };
  const signFinish = () => {
    setOpen(false);
    //判断是否有使用期限
    const timeLimit = goodsInfo?.skuOptions.filter(
      (item: any) => item.type === 1
    );
    //无时间期限规格 直接跳转确认订单页
    if (timeLimit.length === 0) {
      navigate("/orderSettlement", {
        state: {
          selectList: [
            {
              ...goodsInfo?.skuList[0],
              name: goodsInfo.name,
              number: 1,
              thumbnail: goodsInfo?.thumbnail,
              price: goodsInfo?.skuList[0].price,
            },
          ],
          id: goodsInfo.id,
          contract: goodsInfo?.contract || {},
          activityId: goodsInfo?.activityId || "",
        },
      });
    } else {
      navigate("/orderSettlement", {
        state: {
          selectList: [
            {
              ...goodsInfo?.skuList[0],
              number: 1,
              thumbnail: goodsInfo?.thumbnail,
              name: goodsInfo?.name,
              price: goodsInfo?.skuList[0].price,
            },
          ],
          id: goodsInfo.id,
          contract: goodsInfo?.contract || {},
          activityId: goodsInfo?.activityId || "",
          timeList,
        },
      });
    }
  };
  // 切换tab
// 切换tab
const handleTabChange = (activeKey: string) => {
  setCurrentType(activeKey);
  getProductTrees({ ...params, unitType: activeKey });
};
  const { TabPane } = Tabs;

  // 点击查看更多
  const handleShowMore = () => {
    navigate("/goodsShowMore", {
      state: {
        config,
        params,
      },
    });
  };
  console.log(styles, "stylesstyles");

  return (
    <div
      className="goodsshow-output-container"
      style={{
        ...styles,
      }}
    >
      <div className="goodsshow-header-container">
        {showTitle ? (
          <span className="goodsshow-header-title">{title}</span>
        ) : null}

        {showMore && showTitle ? (
                    <div className="look-more" onClick={handleShowMore}>
                    查看更多{" "}
                    <span className=" more-icon-01 iconfont icon-symbol_right"></span>
                  </div>
          
        ) : null}
      </div>
      <div className="recursion-tree-box">
      { fields && fields.includes("unitType") ? (
      <div className="custom-tabs-container">
        <Tabs
          activeKey={String(currentType)}
          onChange={handleTabChange}
          tabBarStyle={{ textAlign: 'center' }}
          tabBarGutter={250} // 去掉每个 tab 之间的间距
          className="custom-tabs" // 添加自定义的 CSS 类
        >
          {types.length &&
            types.map((item:any) => (
              <TabPane
                tab={<span>{item.name}</span>}
                key={item.value}
              >
                {/* 这里可以根据需要展示每个 tab 中的内容 */}
              </TabPane>
            ))}
        </Tabs>
      </div>
    ) : null}

        {fields && fields.includes("dataOverview") ? (
          <LearnInfoGoodsShow
            progressInfo={progressInfo}
            currentType={currentType}
            statics={statics}
          />
        ) : null}

        <RecursionLevelList
          list={productTrees}
          statics={statics}
          callback={handleCallback}
        />
      </div>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        centered
        className="xy-modal"
        footer={null}
        width={680}
      >
        <div className="modal-title">查看协议</div>
        <div className="video-pdf-box">
          <Document
            file={goodsInfo?.contract?.url}
            className="document-pdf"
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                scale={1.5}
              />
            ))}
          </Document>
        </div>
        <div className="modal-footer">
          <div className="sure-button" onClick={signFinish}>
            我已阅读并同意此协议
          </div>
        </div>
      </Modal>
      <QrcodeModal
        isShow={isModalOpen}
        type={"study"}
        onCloseQrCode={handleCloseQrCode}
      />
    </div>
  );
}

export default memo(RecursionTree);

