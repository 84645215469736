import { useEffect, useState, memo } from "react";
import { requestCommodityTypes } from "@/apis/courseDetail";
import { getStyleByTeamplate } from "@/utils/shopDecorate";
import RecursionTree from "./RecursionTree";
import "./goodsShow.scss";

function GoodsShow({ data }: any) {
  const { content = {}, style = {} } = data;
  // 样式配置
  const styles = getStyleByTeamplate(style);
  const [config, setConfig] = useState<any>(
    content?.reduce(
      (result: any[], item: any) => {
        result[item.attr] = item.val;
        return result;
      },
      { styles }
    )
  );
  // 参数
  const [treeParams, setTreeParams] = useState<any>({}); // 树参数-给查看更多使用
  const [loading, setLoading] = useState<any>(false); // 树参数-给查看更多使用

  // 获取商品展示组件树
  const getGoodsProductTrees = async () => {
    const keyValues2 = content?.reduce((result: any[], item: any) => {
      result[item.attr] = item.val;
      return result;
    }, {});
    // 获取商品信息
    let goodsData: any = {}; // 商品信息
    let skuId = null; // 商品sku
    let productList = []; // 产品信息
    // 获取商品id
    try {
      let goodsInfo = keyValues2.dataList[0];
      goodsData = goodsInfo.goods[0];
      productList = goodsInfo.productList;
      skuId = goodsInfo.sku[0].skuId;
    } catch (error) {
      console.error("获取商品id出错", error);
      return;
    }
    const showUnitType2 = keyValues2.fields.includes("unitType");
    const showDataOverview2 = keyValues2.fields.includes("dataOverview");
    let params: any = {
      supplierId: goodsData.supplierId || "",
      commodityId: goodsData.id || "",
      skuId,
      unitType: "",
      dataOverview: showDataOverview2 ? 1 : 0,
      subjectId: "", // 科目id
      limit: keyValues2.showNum,
      productSettings: productList?.map((item: any) => {
        return {
          productId: item.id,
          showMode: item.mode,
          sort: item.index,
          isShow: item.show,
          subjectId: item.subjectId,
        };
      }),
    };
    // 获取分类信息
    const types1 = await requestCommodityTypes(params);
    const result = { ...config, types: types1 || [] };
    params = { ...params, unitType: types1[0] ? types1[0]["value"] : "" };
    setConfig(result);
    setTreeParams(params);
    setLoading(true);
  };

  useEffect(() => {
    getGoodsProductTrees();
  }, []);

  return (
    <>
      {loading ? <RecursionTree config={config} params={treeParams} /> : null}
    </>
  );
}

export default memo(GoodsShow);
