import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import copy from "copy-to-clipboard";
import "./css/index.scss";
import { Table, Button, message } from "antd";
import type { TableProps } from "antd";
import PayModal from "@/components/PayModal";
import ActivityModal from "@/components/ActivityModal";
import OrderStatus from "./comps/OrderStatus";
import Gift from "./comps/Gift";
import GroupInvite from "./comps/GroupInvite";
import { fetchOrderDetail, fetchOrderDetailV2 } from "@/apis/payment";
import { getGroupLateLy } from "@/apis/activity";

interface DataType {
  key: string;
  name: string;
  price1: string;
  price2: string;
  num: number;
}

const GoodsItem = ({ record = {} }: { record: any }) => {
  const { url = "", name = "", skuValueName = "" } = record;
  return (
    <div className={"goods-list-name-wrap"}>
      <div className={"goods-list-img"}>
        <img src={url} alt="" style={{ width: 128, height: 72 }} />
      </div>
      <div className={"goods-list-name"}>
        <span className={"goods-list-name1"}>{name}</span>
        <span className={"goods-list-skuname1"}>
          {skuValueName.replace(/,/g, " ")}
        </span>
      </div>
    </div>
  );
};

const columns: TableProps<DataType>["columns"] = [
  {
    title: "商品",
    dataIndex: "name",
    align: "center",
    className: "paymentstatus-title",
    render: (text, record) => {
      return <GoodsItem record={record} />;
    },
  },
  {
    title: "原价",
    dataIndex: "linePrice",
    align: "center",
    className: "paymentstatus-title",
    render: (text) => {
      return <div className="list-line-price">¥{(+text).toFixed(2)}</div>;
    },
  },
  {
    title: "现价",
    align: "center",
    dataIndex: "realPrice",
    className: "paymentstatus-title",
    render: (text) => {
      return `¥${(+text).toFixed(2)}`;
    },
  },
  {
    title: "数量",
    align: "center",
    className: "paymentstatus-title",
    dataIndex: "number",
  },
];

function PaymentStatus(props: any) {
  const params: any = useParams();
  const navigate = useNavigate();
  const { orderId = "" } = params;
  const [orderInfo, setOrderInfo] = useState<any>({}); // 订单信息
  const [goodsList, setGoodsList] = useState<any[]>([]); // 商品列表
  const [isShowPayModal, setIsShowPayModal] = useState<any>(false); // 打开支付弹框
  const [paymentInfo, setPaymentInfo] = useState<any>({}); // 支付信息
  const [giftList, setGiftList] = useState([]); // 赠品信息
  const [levelInfo, setLevelInfo] = useState<any>({});
  const [replaceInfo, setReplaceInfo] = useState<any>({});
  const [groupInfo, setGroupInfo] = useState<any>({});
  // 获取订单详情
  const getOrderDetail = async (id: string) => {
    const result: any = await fetchOrderDetailV2({ id });
    const { commodities, giftList = [], activityList = [] } = result;
    setOrderInfo(result);
    setGoodsList(commodities);
    setGiftList(giftList);
    activityList?.length > 0 &&
      activityList.map((item: any) => {
        if (item.frameSwitch == 1 && item.type == 7) {
          setLevelInfo(item);
        }
        if (item.frameSwitch == 1 && item.type == 8) {
          setReplaceInfo(item);
        }
      });
    // 查询是否有拼团信息
    const ret = await getGroupLateLy({
      id: orderId,
      commodityId: commodities[0].id,
    });
    if (ret?.groupId) {
      setGroupInfo(ret);
    }
  };

  // 复制订单号
  const copyId = () => {
    copy(orderId);
    message.open({
      type: "success",
      content: "复制成功",
    });
  };

  const refreshOutSide = () => {
    console.log("调用啦");
    getOrderDetail(orderId);
  };

  useEffect(() => {
    getOrderDetail(orderId);
    getGroupInfo(orderId);
  }, []);
  // 查询拼团活动信息
  const getGroupInfo = async (orderId: any) => {};
  // 升级购或换购去购买
  const toBuy = (type: any) => {
    navigate(
      `/activityList?orderId=${orderId}&activityId=${
        type == 7 ? levelInfo?.activityId : replaceInfo?.activityId
      }`
    );
  };
  // 回首页
  const goBackHome = () => {
    navigate("/");
  };
  // 列表头部
  const Header = () => {
    return (
      <div>
        <div className="purchase-table-title">订单信息</div>
        <div className="purchase-info-wrap">
          <span>
            购买时间：
            <span className="order-relative-info">{orderInfo?.createTime}</span>
          </span>
          <span>
            订单编号：
            <span className="order-relative-info">{orderId} </span>
            <span className="copy-btn" onClick={() => copyId()}>
              复制
            </span>
          </span>
        </div>
      </div>
    );
  };

  // 列表底部
  const Footer = () => {
    const { payAmount, discount = 0, status, amount } = orderInfo;
    return (
      <div className="order-amount-info">
        <p>
          商品金额：
          <span className="common-price">¥{(+amount).toFixed(2)}</span>
        </p>
        <p className="discount-price-wrap">
          优惠金额：
          <span className="discount-price common-price">
            - ¥
            {discount > amount ? (+amount).toFixed(2) : (+discount).toFixed(2)}
          </span>
        </p>
        <p>
          {+status === 1 ? "实付款" : "待付款"}：
          <span className="common-price">¥{(+payAmount).toFixed(2)}</span>
        </p>
      </div>
    );
  };

  // 付款成功
  const PaymentSuccess = () => {
    return (
      <div className="pay-status-header-wrap">
        <div className="pay-status-img">
          <img src={require("./images/pay_success.png")} alt="" />
        </div>
        <div className="pay-status-text-wrap">
          <div className="pay-status-text">付款成功</div>
          <div style={{ height: 4 }}></div>
          <div className="pay-status-tip">您已成功购买，快开始学习吧~</div>
        </div>
      </div>
    );
  };

  // 付款失败-待支付
  const PaymentFail = () => {
    return (
      <div className="pay-status-header-wrap">
        <div className="pay-status-img">
          <img src={require("./images/unpay.png")} alt="" />
        </div>
        <div className="pay-status-text-wrap">
          <div className="pay-status-text">待支付</div>
          <div style={{ height: 4 }}></div>
          <div className="pay-status-tip">30分钟后未支付，订单自动关闭~</div>
        </div>
      </div>
    );
  };

  // 去签协议
  const goSign = () => {
    navigate(
      `/Agreement?pdfurl=${orderInfo?.contractUrl}&orderContractId=${orderInfo?.historyId}&isOrder=true&goodsId=${orderInfo?.commodities?.[0]?.id}&redirectUrl=orderDetail/${orderId}`
    );
  };

  const goStudy = () => {
    navigate("/personalCenter");
  };

  // 去支付
  const gotoPay = () => {
    console.log("去支付啦");
    setIsShowPayModal(true);
  };

  // 关闭弹框
  const handleClose = () => {
    setIsShowPayModal(false);
  };

  return (
    <div className="pay-order-result-wrap">
      <OrderStatus status={+orderInfo.status == 5 ? 1 : +orderInfo.status} />
      {groupInfo?.groupId && groupInfo?.status == 1 ? (
        <GroupInvite
          groupInfo={groupInfo}
          orderId={orderId}
          commodityId={goodsList[0].id}
          goBackHome={goBackHome}
        />
      ) : null}
      {/* {+orderInfo.status === 1 ? <PaymentSuccess /> : <PaymentFail />} */}
      {giftList?.length > 0 ? <Gift giftList={giftList} /> : null}

      <div className="order-list-wrap">
        <Header />
        <Table
          columns={columns}
          dataSource={goodsList}
          bordered
          pagination={false}
          className="pay-order-goods-table"
        />
        <Footer />
      </div>
      <div className="order-tip">
        提示：所购商品可以在个人中心-我的订单-已购买中查看。
      </div>
      <div className="goto-study-btn-wrap">
        {+orderInfo.status === 0 ? (
          <div className="goto-study-btn" onClick={gotoPay}>
            去支付
          </div>
        ) : null}
        {+orderInfo.status === 1 ? (
          orderInfo?.needSign ? (
            <div className="goto-study-btn" onClick={goSign}>
              签署协议
            </div>
          ) : (
            <div className="goto-study-btn" onClick={goStudy}>
              去学习
            </div>
          )
        ) : null}
      </div>
      {isShowPayModal ? (
        <PayModal
          onClose={handleClose}
          orderId={orderId}
          refreshOutSide={refreshOutSide}
        />
      ) : null}
      {replaceInfo?.activityId && orderInfo.status == 1 ? (
        <ActivityModal type={1} info={replaceInfo} toBuy={() => toBuy(8)} />
      ) : null}

      {levelInfo?.activityId && orderInfo.status == 1 ? (
        <ActivityModal type={0} info={levelInfo} toBuy={() => toBuy(7)} />
      ) : null}
    </div>
  );
}
export default PaymentStatus;
