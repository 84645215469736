import React from "react"
import PreviewAgreement from "./comps/Preview"
import SignAgreement from "./comps/SignAgreement"
import SignFinish from "./comps/SignFinish"

function Agreement() {
  return (
    <div>
      <PreviewAgreement />
    </div>
  )
}
export default Agreement
