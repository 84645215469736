import { useNavigate } from "react-router-dom";
import userStore from "@/store/user";
import "./css/loginAready.scss";
function LoginAlready() {
  const navigate = useNavigate();
  const userInfo = userStore.getUserInfo();
  return (
    <div className="login-already">
      <div className="already-title">
        <img src={userInfo.avatar} alt="avatar" className="user-img" />
        <div className="already-text">
          <p className="text-name">{userInfo.nickname}</p>
          <p className="text-id">ID: {userInfo.uid}</p>
        </div>
      </div>
      <div className="already-text2">
        <div
          className="my-course"
          onClick={() => {
            navigate("/personalCenter");
          }}
        >
          我的课程
        </div>
        <div>|</div>
        <div
          className="my-order"
          onClick={() => {
            navigate("/personalCenter?topNavIndex=3");
          }}
        >
          我的订单
        </div>
      </div>
      <div className="already-tostudy">
        <div className="already-img"></div>
        <button
          onClick={() => {
            navigate("/personalCenter");
          }}
        >
          去学习
        </button>
      </div>
    </div>
  );
}
export default LoginAlready;
