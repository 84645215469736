/*
  学习引导
*/
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ex from "./images/example.png";
import no from "./images/example-no.png";
import bookMark from "./images/bookmark.png";
import { handleBread } from "@/utils/bread";
import { getNewStudy } from "@/apis/studyGuide";
import { Button } from "antd";
import { setPlayUnit } from "@/utils/storageFactory";
import "./css/index.scss";
const StudyGuide = (com: any) => {
  const {
    // backgroundColor,
    // backgroundImage,
    color,
    customStyle,
    showTitle,
    showMore,
    showBefore,
    showItem,
    title,
  } = com.data;
  const toGood = com.data.goods;

  const {
    paddingBottom,
    paddingLeft,
    paddingTop,
    paddingRight,
    backgroundColor,
    backgroundImage,
  } = customStyle;
  const style = {
    backgroundColor: backgroundColor,
    backgroundImage,
    color: color,
    paddingTop,
    paddingRight,
    paddingLeft,
    paddingBottom,
  };

  const navigate = useNavigate();
  const [more, setMore] = useState(showMore);
  const [show, setshow] = useState(true);
  const [showAll, setshowAll] = useState(false);
  const [body, setBody] = useState({
    averageSpeedRate: 1.1,
    commodity: {
      id: "MER00000044362169",
      name: "222",
      skuName: ["11"],
      thumbnail:
        "https://chestnut-acc.wangxiao.net/inner/image/cd2fb63eb0ab411b9bcba9a6f3d738a8.png",
    },
    commodityId: "MER00000044362169",
    doneDuration: 10,
    donePage: 0,
    examDone: 1,
    latestStudyTime: "2023-11-08 12:41:10",
    practiceDone: 10,
    realPlayedTime: 11,
    unitName: "424",
    section: "测试节点1（视频）",
    unitId: 1,
    productId: "",
  });

  useEffect(() => {
    getStudy();
  }, []); // 空依赖数组表示仅在组件挂载时执行
  // 跳转更多
  const tomore = () => {
    handleBread({ title: `学习中`, href: `/allstudy/` });
    navigate("/allstudy/", { state: { showItem } });
  };
  // 跳转去学习
  const toStudy = (productId: string) => {
    setPlayUnit("", "", "", ""); //清空当前的选中
    navigate(`/videoPlay?productId=${productId}&isPurchase=true`);
  };
  // 跳转解锁
  const toUnlock = () => {
    navigate(`/courseDetail?id=${toGood[0].id}`);
  };
  const getStudy = async () => {
    const res = await getNewStudy();
    setBody(res);

    if (Object.keys(res).length === 0) {
      setshowAll(false);
    } else {
      setshowAll(true);
      if (res.isPurchase === false) {
        setshow(false);
        setMore(false);
      } else setshow(true);
    }
  };
  const getHour = (time: number) => Math.floor(time / 3600);
  const getMin = (time: number) => Math.floor((time % 3600) / 60);
  const getSeconds = (time: number) => time % 60;

  return (
    <div>
      {showAll ? (
        <div className="content" style={style}>
          {show ? (
            <div>
              {showTitle ? (
                <div className="comp-top">
                  <div className="comp-title" style={{ color: style.color }}>
                    {title}
                  </div>
                  {more ? (
                    <div className="look-more" onClick={() => tomore()}>
                      查看更多{" "}
                      <span className=" more-icon-01 iconfont icon-symbol_right"></span>
                    </div>
                  ) : null}
                </div>
              ) : null}
              <div className="box-card" style={{ paddingTop: 32 }}>
                <div className="good">
                  <img
                    className="good-image"
                    src={body.commodity.thumbnail}
                    alt="商品缩略图"
                  />
                  <div className="good-right">
                    <div className="good-course" style={{ color: style.color }}>
                      {body.commodity.name}
                    </div>
                    <p className="good-subject">
                      按照科目：{body.commodity.skuName}
                    </p>
                    {/* 进度条 */}
                    <div className="process">
                      <div
                        className="process-left"
                        style={{ width: `${body.averageSpeedRate * 100}%` }}
                      />
                      <div
                        className="process-label"
                        style={{
                          left: `${(826 - 50) * body.averageSpeedRate}px`,
                        }}
                      >
                        {Math.floor(body.averageSpeedRate * 100)}%{" "}
                      </div>
                    </div>
                  </div>
                </div>

                {/* 数据概览 */}
                {showItem[0] === "about" ? (
                  <div>
                    {getSeconds(body.realPlayedTime) ? (
                      <div className="course">
                        <div className="course-container">
                          <div className="course-data">
                            {getHour(body.realPlayedTime)}
                            <span className="light-label">小时 </span>
                            {getMin(body.realPlayedTime)}
                            <span className="light-label">分 </span>
                            {getSeconds(body.realPlayedTime)}
                            <span className="light-label">秒 </span>
                          </div>
                          <span className="course-label">视频</span>
                        </div>
                      </div>
                    ) : null}
                    {body.practiceDone ? (
                      <div className="course">
                        <div className="course-container">
                          <div className="course-data">
                            {body.practiceDone}
                            <span className="light-label">题 </span>
                          </div>
                          <div className="course-label">练习</div>
                        </div>
                      </div>
                    ) : null}
                    {body.examDone ? (
                      <div className="course">
                        <div className="course-container">
                          <div className="course-data">
                            {body.examDone}
                            <span className="light-label">题 </span>
                          </div>
                          <div className="course-label">考试</div>
                        </div>
                      </div>
                    ) : null}
                    {body.donePage ? (
                      <div className="course">
                        <div className="course-container">
                          <div className="course-data">
                            {body.donePage}
                            <span className="light-label">页 </span>
                          </div>
                          <div className="course-label">资料</div>
                        </div>
                      </div>
                    ) : null}
                    {getSeconds(body.doneDuration) ? (
                      <div className="course">
                        <div className="course-container">
                          <div className="course-data">
                            {getHour(body.doneDuration)}
                            <span className="light-label">小时 </span>
                            {getMin(body.doneDuration)}
                            <span className="light-label">分 </span>
                            {getSeconds(body.doneDuration)}
                            <span className="light-label">秒 </span>
                          </div>

                          <div className="course-label">直播</div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                {/* 底部课程 */}
                <p className="last">上次学到:</p>
                <div className="bottom">
                  <div className="bottom-cotainer">
                    <img
                      src={bookMark}
                      className="bottom-icon"
                      alt="商品缩略图"
                    />
                    <h3 className="bottom-course">{body.unitName}</h3>
                  </div>
                  <Button
                    className="study-button"
                    onClick={() => toStudy(body.productId)}
                  >
                    去学习
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
          {/*当未购买商品时展示购买前示例图片*/}
          {showBefore && !show ? (
            <div>
              {showTitle ? (
                <div className="comp-top">
                  <div className="comp-title" style={{ color: style.color }}>
                    {title}
                  </div>
                  {more ? (
                    <div className="look-more" onClick={() => tomore()}>
                      查看更多{" "}
                      <span className=" more-icon-01 iconfont icon-symbol_right"></span>
                    </div>
                  ) : null}
                </div>
              ) : null}
              {showItem[0] === "about" ? (
                <div className="fa-example-showdata">
                  <img src={ex} className="example-image" alt="Example" />
                  <Button className="unlock" onClick={toUnlock}>
                    解锁我的数据
                  </Button>
                  <div className="example-data">示例数据</div>
                </div>
              ) : (
                <div className="fa-example-nodata">
                  <img src={no} className="no-image" alt="Example" />
                  <Button className="unlock" onClick={toUnlock}>
                    解锁我的数据
                  </Button>
                  <div className="example-data">示例数据</div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default StudyGuide;
