/* eslint-disable  */
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { message } from "antd";
import { debounce } from "lodash";
import userStore from "@/store/user";
import OrderInfo from "./comps/OrderInfo";
import UseLimit from "./comps/UseLimit";
import Counp from "./comps/Counp";
import PayModal from "@/components/PayModal";
import AddPriceBuy from "./comps/AddPriceBuy";
import Gift from "./comps/Gift";
import {
  getPayCoupon,
  createOrder,
  createOrderV2,
  getSignStatus,
  signRecord,
  getChannelId,
  getRealPrice,
} from "@/apis/settlement";
import { getCDNUrl } from "@/config/index";
import { getAddBuyInfo, getGiftInfo } from "@/apis/activity";
import "./css/index.scss";
import ActivityList from "./comps/ActivityList";

function OrderSettlement(props: any) {
  const {
    state: {
      selectList,
      id,
      contract,
      activityId,
      timeList = [],
      originalOrderId = "",
      originalActivityId = "",
    },
  } = useLocation();
  const [searchParams] = useSearchParams();
  const orderContractId = searchParams.get("orderContractId");
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [couponList, setCouponList] = useState<any[]>([]);
  const [goodNums, setGoodNums] = useState<number>(0);
  const [currentSku, setCurrentSku] = useState<any>(selectList);
  const [orderId, setOrderId] = useState(""); // 订单号
  const [isShowPayModal, setIsShowPayModal] = useState<any>(false); // 打开支付弹框
  const [checked, setChecked] = useState(false); //是否勾选协议
  const [curCoupon, setCurCoupon] = useState<any>([]); // 选中的优惠券
  const [recordId, setRecordId] = useState(""); //协议记录id
  const [fileUrl, setFileUrl] = useState<any>("");
  const [signStatus, setSignStatus] = useState(0); //协议签署状态 0未签署 1已签署 3签署中
  const [channelId, setChannelId] = useState(""); // 渠道id
  const [giftInfoOrg, setGiftInfoOrg] = useState([]); // 赠品活动列表(原始数据)
  const [giftInfo, setGiftInfo] = useState<any>([]); // 赠品活动列表(展示数据)
  const [addBuyInfoOrg, setAddBuyInfoOrg] = useState<any>({}); // 加价购活动详情(原始数据)
  const [addBuyInfo, setAddBuyInfo] = useState<any>({}); // 加价购活动详情(展示数据)
  const [curGiftInfo, setCurGiftInfo] = useState<any[]>([]); // 当前选中的赠品活动列表-活动不叠加时展示
  const [curAddPriceInfo, setCurAddPriceInfo] = useState<any[]>([]); // 当前选中的加价购活动
  const [clearGroupAndSeckill, setClearGroupAndSeckill] = useState(false); // 是否需要清除秒杀/拼团
  const [showActivityList, setShowActivityList] = useState(false);
  const [resultPrice, setResultPrice] = useState<any>(0); //最后的实付金额
  const [resultDiscount, serResultDiscount] = useState<any>(0); // 最后的优惠金额
  const [couponResultAmount, setCouponResultAmount] = useState<any>(0); // 当前优惠金额
  const [markupResultAmount, setMarkupResultAmount] = useState<any>(0); // 当前加价金额
  const [groupSkillPrice, setGroupSkillPrice] = useState(0); // 暂存拼团秒杀价
  const [orderAmount, setOrderAmount] = useState(0); //订单原金额 不参与任何活动
  const [curActInfo, setCurActInfo] = useState<any[]>([]); // 存储当前的活动信息 用于下单
  const addPriceRef = useRef<any>(null);
  const couponRef = useRef<any>(null);
  const activityListRef = useRef<any>(null);
  useEffect(() => {
    const getChannelInfo = async () => {
      const res = await getChannelId();
      setChannelId(res?.channelId);
    };
    getChannelInfo();
    setCurrentSku(selectList);
  }, []);
  useEffect(() => {
    // 获取该商品相关优惠券信息
    if (id && currentSku.length > 0) {
      let amount: any = 0,
        tempGoodNum: any = 0,
        skuIds: any = [];
      currentSku.map((item: any) => {
        amount = Number(
          (
            Number(amount.toFixed(2)) +
            Number((item.number * item.price).toFixed(2))
          ).toFixed(2)
        );
        tempGoodNum = tempGoodNum + item.number;
        skuIds.push(item.skuId);
      });
      setGoodNums(tempGoodNum);
      if (!originalActivityId && !originalOrderId) {
        getCouponList(id, amount);
        console.log(selectList, "selectListselectList", skuIds);
        getPriceAndActivityFirst(id, skuIds);
      } else {
        // 升级购 换购
        getReplaceOrLevelPrice(originalOrderId, originalActivityId);
      }
    }
  }, [id, currentSku, activityId, originalOrderId, originalActivityId]);
  // 升级购商品 换购商品 获取价格信息
  const getReplaceOrLevelPrice = async (
    originalOrderId: string,
    originalActivityId: string
  ) => {
    if (!originalActivityId || !originalActivityId) {
      return;
    }
    let commodities: any[] = currentSku.map((item: any) => {
      return { number: item.number, skuId: item.skuId, id };
    });
    let data: any = {
      commodities,
      coupons: [],
      activityList: [
        {
          id: originalActivityId,
          originalOrderId: originalOrderId,
        },
      ],
    };
    setCurActInfo([
      {
        id: originalActivityId,
        originalOrderId: originalOrderId,
      },
    ]);
    const res = await getRealPrice(data);
    // 存储金额信息
    setResultPrice(res?.amount);
    serResultDiscount(res?.discount);
    setCouponResultAmount(res?.couponResultAmount);
    setMarkupResultAmount(res?.markupResultAmount);
  };
  // 首次获取价格信息以及活动信息
  const getPriceAndActivityFirst = async (id: string, skuIds: any[]) => {
    if (currentSku?.length > 0 && id) {
      let commodities: any[] = currentSku.map((item: any) => {
        return { number: item.number, skuId: item.skuId, id };
      });
      let data: any = {
        commodities,
        coupons: [],
        activityList: activityId ? [{ id: activityId }] : [],
      };
      setCurActInfo(activityId ? [{ id: activityId }] : []);
      const res = await getRealPrice(data);
      // 存储金额信息
      setResultPrice(res?.amount);
      serResultDiscount(res?.discount);
      setGroupSkillPrice(res?.amount); //
      setOrderAmount(res?.orderAmount);
      setCouponResultAmount(res?.couponResultAmount);
      setMarkupResultAmount(res?.markupResultAmount);
      // 获取赠品信息 过滤不满足门槛
      const giftRes = await getGiftInfo({ skuIds });
      if (giftRes?.giftActivityVoList?.length > 0) {
        const resultData = giftRes?.giftActivityVoList.filter((item: any) => {
          return (
            (item.overlayScreen == 0 && item.threshold <= res?.orderAmount) ||
            (item.overlayScreen == 1 && item.threshold <= res?.amount)
          );
        });
        setGiftInfo(resultData || []);
        setGiftInfoOrg(giftRes?.giftActivityVoList || []);
        // 初始默认选中不叠加的赠品
        let tempCurGift: any[] = [];
        if (resultData?.length > 0) {
          resultData.map((child: any) => {
            if (child?.overlayScreen == 1) {
              tempCurGift.push(child);
            }
          });
        }
        console.log(tempCurGift, "gift--000");
        setCurGiftInfo(tempCurGift);
        activityListRef?.current?.propSelect &&
          activityListRef?.current?.propSelect(tempCurGift);
      }
      // 获取加价购信息 过滤不满足门槛
      const addPriceRes = await getAddBuyInfo({ commodityId: id });
      if (addPriceRes?.activityId) {
        setAddBuyInfoOrg(addPriceRes);
        if (
          (addPriceRes?.overlayScreen == 0 &&
            addPriceRes?.threshold <= res?.orderAmount) ||
          (addPriceRes?.overlayScreen == 1 &&
            addPriceRes?.threshold <= res?.amount)
        ) {
          setAddBuyInfo(addPriceRes);
        } else {
          setAddBuyInfo({});
        }
      }
    } else {
      setResultPrice(0);
      serResultDiscount(0);
      setCouponResultAmount(0);
      setMarkupResultAmount(0);
    }
  };

  const getCouponList = async (id: string, amount: number) => {
    const res = await getPayCoupon({ id, amount });
    // 排序-可用在前
    let resultArr: any = [];
    if (res?.length > 0) {
      res.map((item: any) => {
        if (item.usable) {
          resultArr.push(item);
        }
      });
      res.map((item: any) => {
        if (!item.usable) {
          resultArr.push(item);
        }
      });
      if (resultArr?.length > 0) {
        setCouponList(resultArr);
        return;
      }
    }
    setCouponList(res || []);
  };
  const changeSku = (item: any) => {
    setCurrentSku([{ ...item, number: 1 }]);
    console.log(item, "change--");
  };
  // 立即结算
  const payNow = debounce(async () => {
    if (userStore.getToken()) {
      if (!checked) {
        messageApi.open({
          type: "warning",
          content: "请勾选产品协议以及学员服务条款",
        });
        return;
      }
      let data = currentSku;
      if (orderContractId) {
        data.forEach((item: any) => {
          item.id = id;
          item.commodityType = 1;
          item.certificateId = null;
          item.contractHistoryId = orderContractId;
          // item.amount = Number(item.price) * Number(item.number);
        });
      } else {
        data.forEach((item: any) => {
          item.id = id;
          item.commodityType = 1;
          item.certificateId = null;
          // item.amount = Number(item.price) * Number(item.number);
        });
      }
      let parmas: any = {};
      let commodities = currentSku.map((item: any) => {
        return {
          id: item.id,
          skuCode: item.skuCode,
          number: item.number,
        };
      });
      parmas = {
        commodities,
        remark: "",
        coupons: curCoupon?.length > 0 ? [curCoupon[0].id] : [],
        activityList: curActInfo || [],
      };

      // const res = await createOrderV2(parmas);
      // // console.log(res, "resssssss");

      const { orderId = "", isFree } = await createOrderV2(parmas);
      setOrderId(orderId);
      if (isFree) {
        // 直接跳转 支付成功页
        navigate(`/paymentStatus/${orderId}`);
      } else {
        setIsShowPayModal(true);
      }
    }
  }, 500);
  const contractSign = async (recordId: any) => {
    const res = await getSignStatus(recordId);
    res.exclusiveContractUrl ? setFileUrl(res.exclusiveContractUrl) : null;
    setSignStatus(res.status);
  };
  const createSignRecord = async () => {
    const recordIdTemp = await signRecord({ commodityId: id });
    setRecordId(recordIdTemp);
  };
  // const goSign = () => {
  //   navigation.navigate('AgreementDetail', {
  //     fileUrl: fileUrl ? fileUrl : goodsData.contract?.url,
  //     isSigned: signStatus === 0 ? 0 : 1,
  //     recordId: recordId,
  //     isOrder: false,
  //     goodsId: goodsData.id,
  //   });
  // };
  // 签署协议
  const goSign = () => {
    navigate(
      `/Agreement?pdfurl=${
        fileUrl ? fileUrl : contract.url
      }&orderContractId=${recordId}&isOrder=false&goodsId=${id}&redirectUrl=orderSettlement`,
      {
        state: {
          currentSku,
          id,
          contract,
          activityId,
        },
      }
    );
  };

  useEffect(() => {
    if (recordId) {
      contractSign(recordId);
    }
  }, [recordId]);
  useEffect(() => {
    if (orderContractId) {
      setRecordId(orderContractId);
      contractSign(orderContractId);
    } else if (
      (contract?.signTime === 1 || contract?.signTime === 2) &&
      !orderContractId
    ) {
      createSignRecord();
    }
  }, [orderContractId]);
  // 关闭弹框
  const handleClose = () => {
    setIsShowPayModal(false);
  };
  // 清除选择的加价购
  const clearAddPriceSelect = () => {
    addPriceRef?.current?.clearSelect && addPriceRef?.current?.clearSelect();
    setCurAddPriceInfo([]);
  };
  // 清除选择的优惠券
  const clearCouponSelect = () => {
    couponRef?.current?.clearSelect && couponRef?.current?.clearSelect();
    setCurCoupon([]);
  };
  // 更新价格信息
  const updatePriceInfo = async (params: any) => {
    const res = await getRealPrice(params);
    setResultPrice(res?.amount);
    serResultDiscount(res?.discount);
    setCouponResultAmount(res?.couponResultAmount);
    setMarkupResultAmount(res?.markupResultAmount);
  };
  // 选择的赠品活动变化 --考虑互斥(1.赠品本身互斥 2.加价购影响 3.都互斥)  --考虑门槛以及金额
  const getCurGiftActivity = async (curList: any) => {
    if (originalOrderId || originalActivityId) {
      return;
    }
    let commodities: any[] = currentSku.map((item: any) => {
      return { number: item.number, skuId: item.skuId, id };
    });
    let coupons: any[] = [],
      activityList: any[] = [];
    // 选择了赠品活动时
    if (curList?.length > 0) {
      // 存在不叠加互斥的情况时
      if (
        (addBuyInfo?.overlayScreen == 0 && curAddPriceInfo?.length > 0) ||
        curList?.some((x: any) => x?.overlayScreen == 0)
      ) {
        clearAddPriceSelect();
        curList.map((item: any) => {
          activityList.push({
            id: item.id,
          });
        });
        // 赠品本身不可叠加 加价购叠加存疑 需要清除赠品外的所有活动 重新计算价格(只带赠品) 设置优惠券 加价购 秒杀信息
        // 赠品本身可叠加时 独加价购不可叠加时 清除加价购 重新计算价格(带优惠券) 设置加价购信息
        if (!curList?.every((x: any) => x?.overlayScreen == 1)) {
          clearCouponSelect();
          setClearGroupAndSeckill(true);
        } else {
          curCoupon.length > 0 &&
            curCoupon.map((item: any) => {
              coupons.push(item.id);
            });
          activityId &&
            activityList.push({
              id: activityId,
            });
        }
        const res = await getRealPrice({
          commodities,
          coupons,
          activityList,
        });
        setResultPrice(res?.amount);
        serResultDiscount(res?.discount);
        setCouponResultAmount(res?.couponResultAmount);
        setMarkupResultAmount(res?.markupResultAmount);
        if (
          (addBuyInfoOrg?.overlayScreen == 0 &&
            addBuyInfoOrg?.threshold <= res?.orderAmount) ||
          (addBuyInfoOrg?.overlayScreen == 1 &&
            addBuyInfoOrg?.threshold <= res?.amount)
        ) {
          setAddBuyInfo(addBuyInfoOrg);
        } else {
          setAddBuyInfo({});
        }
        !curList?.every((x: any) => x?.overlayScreen == 1) &&
          getCouponList(id, groupSkillPrice);
      } else {
        // 全部可叠加不互斥时 不存在互斥下 赠品的选择的变化不会影响门槛 以及当前的价格 只需暂存赠品活动信息
        curList.map((item: any) => {
          activityList.push({
            id: item,
          });
          curCoupon.length > 0 &&
            curCoupon.map((item: any) => {
              coupons.push(item.id);
            });
          curAddPriceInfo?.length > 0 &&
            curAddPriceInfo.map((item: any) => {
              activityList.push({ id: addBuyInfo.activityId, ...item });
            });
          activityId &&
            activityList.push({
              id: activityId,
            });
        });
      }
    } else {
      // 只有原先选择的赠品本身不叠加时 赠品清空会有影响 秒杀/拼团的复原 -> 影响价格 -> 重新设置优惠券 加价购
      // 赠品本身叠加时 清空无影响 只是少了一个活动的参与 不影响价格 -> 重新设置活动
      let flag = false; // 是否影响价格
      if (curGiftInfo?.length > 0) {
        if (curGiftInfo?.some((x: any) => x?.overlayScreen == 0)) {
          flag = true;
        }
      }
      setClearGroupAndSeckill(false);
      activityId && activityList.push({ id: activityId });
      if (flag) {
        const res = await getRealPrice({
          commodities,
          coupons,
          activityList,
        });
        setResultPrice(res?.amount);
        serResultDiscount(res?.discount);
        setCouponResultAmount(res?.couponResultAmount);
        setMarkupResultAmount(res?.markupResultAmount);
        if (
          (addBuyInfoOrg?.overlayScreen == 0 &&
            addBuyInfoOrg?.threshold <= res?.orderAmount) ||
          (addBuyInfoOrg?.overlayScreen == 1 &&
            addBuyInfoOrg?.threshold <= res?.amount)
        ) {
          setAddBuyInfo(addBuyInfoOrg);
        } else {
          setAddBuyInfo({});
        }
      } else {
        curAddPriceInfo?.length > 0 &&
          curAddPriceInfo.map((item: any) => {
            activityList.push({ id: addBuyInfo.activityId, ...item });
          });
      }
    }
    setCurActInfo(activityList);
    setCurGiftInfo(curList);
  };
  // 选择了加价购  --考虑互斥(1.加价购本身互斥 2.赠品影响 3.都互斥) --考虑门槛以及金额
  const getSelectAddPrice = async (curList: any) => {
    let commodities: any[] = currentSku.map((item: any) => {
      return { number: item.number, skuId: item.skuId, id };
    });
    let coupons: any[] = [],
      activityList: any[] = [];
    if (curList?.length > 0) {
      // 所选加价购不为空时 即从无 -> 有 有 -> 有
      // case 1: 加价购自身可叠加时  清除勾选的不可叠加的赠品的选中 还原秒杀价 赠品的门槛提高或减少 重设赠品信息(保持选中) 优惠券不动
      // case 2: 加价购自身不可叠加时 清除全部赠品 优惠券 秒杀
      // case 3: 加价购与赠品都可叠加 包含于case1中
      // case 1:
      if (addBuyInfo?.overlayScreen == 1) {
        let curAddPrice: number = 0;
        curList.map((item: any) => {
          const x = addBuyInfo.upgradeSkuData.find(
            (child: any) =>
              item.commodityId == child.commodityId && item.skuId == child.skuId
          );
          curAddPrice = curAddPrice + x.amount;
        });
        setClearGroupAndSeckill(false);
        activityId && activityList.push({ id: activityId });
        // 加价购门槛也需要处理 (触发互斥后门槛发生变化 不满足门槛后清除自身)
        let tempPrice0: any = groupSkillPrice;
        if (curCoupon?.length > 0) {
          if (curCoupon[0].type == 0) {
            tempPrice0 = groupSkillPrice - curCoupon[0].discount;
          } else if (curCoupon[0].type == 1) {
            tempPrice0 = groupSkillPrice * 0.1 * curCoupon[0].discount;
          }
        }
        if (tempPrice0 < 0) {
          tempPrice0 = 0;
        }
        if (addBuyInfo.threshold <= tempPrice0) {
          curList.map((item: any) => {
            activityList.push({ id: addBuyInfo.activityId, ...item });
          });
        } else {
          setAddBuyInfo({});
          clearAddPriceSelect();
          curAddPrice = 0;
        }
        // 处理赠品
        // 过滤后的赠品展示数据
        const tempGiftInfo: any[] = [];
        if (giftInfoOrg?.length > 0) {
          let tempPrice1 = tempPrice0 + curAddPrice;
          if (tempPrice1 < 0) {
            tempPrice1 = 0;
          }
          giftInfoOrg.map((item: any) => {
            if (
              (item.overlayScreen == 1 && item.threshold <= tempPrice1) ||
              (item.overlayScreen == 0 && item.threshold <= orderAmount)
            ) {
              tempGiftInfo.push(item);
            }
          });
        }
        setGiftInfo(tempGiftInfo);
        // 过滤后的赠品选中 (需从过滤后的展示数据中选出已收集的且为可叠加的)
        let tempCurGift: any[] = [];
        tempGiftInfo.length > 0 &&
          tempGiftInfo.map((item: any) => {
            if (
              JSON.stringify(curGiftInfo).includes(JSON.stringify(item)) &&
              item.overlayScreen == 1
            ) {
              tempCurGift.push(item);
            }
          });
        activityListRef?.current?.clearSelect &&
          activityListRef?.current?.clearSelect();
        activityListRef?.current?.propSelect &&
          activityListRef?.current?.propSelect(tempCurGift);
        setCurGiftInfo(tempCurGift);
        tempCurGift?.length > 0 &&
          tempCurGift.map((item: any) => {
            activityList.push({
              id: item.id,
            });
          });
        updatePriceInfo({
          commodities,
          coupons,
          activityList,
        });
      } else {
        // case 2
        setClearGroupAndSeckill(true);
        activityListRef?.current?.clearSelect &&
          activityListRef?.current?.clearSelect();
        setCurGiftInfo([]);
        clearCouponSelect();
        curList.map((item: any) => {
          activityList.push({ id: addBuyInfo.activityId, ...item });
        });
        // 计算价格
        const res = await getRealPrice({
          commodities,
          coupons,
          activityList,
        });
        setResultPrice(res?.amount);
        serResultDiscount(res?.discount);
        setCouponResultAmount(res?.couponResultAmount);
        setMarkupResultAmount(res?.markupResultAmount);
        // 获取优惠券
        getCouponList(id, groupSkillPrice);
        // 过滤赠品展示数据
        const tempGiftArr: any[] = [];
        giftInfoOrg.map((item: any) => {
          if (
            (item.overlayScreen == 1 && item.threshold <= groupSkillPrice) ||
            (item.overlayScreen == 0 && item.threshold <= res.orderAmount)
          ) {
            tempGiftArr.push(item);
          }
        });
        setGiftInfo(tempGiftArr);
      }
      // return;
      // // 存在互斥不叠加的情况时
      // if (
      //   addBuyInfo?.overlayScreen == 0 ||
      //   curGiftInfo?.some((x: any) => x?.overlayScreen == 0)
      // ) {
      //   // 自身可以叠加时 清除勾选的不可叠加的赠品 优惠券 赠品展示信息 不需要重新设置 但需要更新当前活动信息 和 当前价格信息
      //   if (addBuyInfo?.overlayScreen == 1) {
      //     setClearGroupAndSeckill(false);
      //     activityListRef?.current?.clearSelectOnly &&
      //       activityListRef?.current?.clearSelectOnly();
      //     let tempArr: any[] = [];
      //     if (curGiftInfo?.length > 0) {
      //       tempArr =
      //         curGiftInfo.filter((x: any) => x.overlayScreen == 1) || [];
      //     }
      //     activityId && activityList.push({ id: activityId });
      //     curList.map((item: any) => {
      //       activityList.push({ id: addBuyInfo.activityId, ...item });
      //     });
      //     tempArr?.length > 0 &&
      //       tempArr.map((item: any) => {
      //         activityList.push({
      //           id: item.id,
      //         });
      //       });
      //     curCoupon.length > 0 &&
      //       curCoupon.map((item: any) => {
      //         coupons.push(item.id);
      //       });
      //     setCurGiftInfo(tempArr);
      //     // 更新价格信息
      //     const res = await getRealPrice({
      //       commodities,
      //       coupons,
      //       activityList,
      //     });
      //     setResultPrice(res?.amount);
      //     serResultDiscount(res?.discount);
      //     setCouponResultAmount(res?.couponResultAmount);
      //     setMarkupResultAmount(res?.markupResultAmount);
      //   } else {
      //     // 自身不叠加时 需要清除本身以外全部的活动 重新获取价格 以及重新设置活动展示信息 赠品(门槛-- 赠品本身可叠加时:拼团秒杀价 赠品本身不可叠加时: 非拼团秒杀价) 和优惠券信息(拼团秒杀价)
      //     activityListRef?.current?.clearSelect &&
      //       activityListRef?.current?.clearSelect();
      //     setCurGiftInfo([]);
      //     clearCouponSelect();
      //     setClearGroupAndSeckill(true);
      //     curList.map((item: any) => {
      //       activityList.push({ id: addBuyInfo.activityId, ...item });
      //     });
      //     const res = await getRealPrice({
      //       commodities,
      //       coupons,
      //       activityList,
      //     });
      //     setResultPrice(res?.amount);
      //     serResultDiscount(res?.discount);
      //     setCouponResultAmount(res?.couponResultAmount);
      //     setMarkupResultAmount(res?.markupResultAmount);
      //     const tempGiftArr: any[] = [];
      //     giftInfoOrg.map((item: any) => {
      //       if (
      //         (item.overlayScreen == 1 && item.threshold <= groupSkillPrice) ||
      //         (item.overlayScreen == 0 && item.threshold <= res.orderAmount)
      //       ) {
      //         tempGiftArr.push(item);
      //       }
      //     });
      //     setGiftInfo(tempGiftArr);
      //     getCouponList(id, groupSkillPrice);
      //   }
      // } else {
      //   // 不存在互斥 全部叠加的情况时 所有活动都参与 此时加价购会影响赠品的门槛 赠品的门槛为(当前实付金额) 所以需要重新设置赠品信息 但是需保留原来已选择的
      //   // 优惠券门槛与加价购无关 不用更新数据
      //   activityId &&
      //     activityList.push({
      //       id: activityId,
      //     });
      //   curList.map((item: any) => {
      //     activityList.push({ id: addBuyInfo.activityId, ...item });
      //   });
      //   curGiftInfo?.length > 0 &&
      //     curGiftInfo.map((item: any) => {
      //       activityList.push({
      //         id: item.id,
      //       });
      //     });
      //   curCoupon.length > 0 &&
      //     curCoupon.map((item: any) => {
      //       coupons.push(item.id);
      //     });
      //   const res = await getRealPrice({
      //     commodities,
      //     coupons,
      //     activityList,
      //   });
      //   setResultPrice(res?.amount);
      //   serResultDiscount(res?.discount);
      //   setCouponResultAmount(res?.couponResultAmount);
      //   setMarkupResultAmount(res?.markupResultAmount);
      //   // 重新设置赠品信息 并选中
      //   const tempGiftArr: any[] = [];
      //   if (giftInfoOrg?.length > 0) {
      //     giftInfoOrg.map((item: any) => {
      //       if (item.threshold <= res?.amount) {
      //         tempGiftArr.push(item);
      //       }
      //     });
      //   }
      //   setGiftInfo(tempGiftArr);
      //   let tempCurGift: any[] = [];
      //   tempGiftArr.length > 0 &&
      //     tempGiftArr.map((item: any) => {
      //       if (JSON.stringify(curGiftInfo).includes(JSON.stringify(item))) {
      //         tempCurGift.push(item);
      //       }
      //     });
      //   console.log(tempCurGift, "gift--111");
      //   activityListRef?.current?.propSelect &&
      //     activityListRef?.current?.propSelect(tempCurGift);
      //   setCurGiftInfo(tempCurGift);
      // }
    } else {
      // 所选加价购为空时 即从有 -> 无
      // case 1: 加价购自身叠加时 需要考虑 加价购减少引起的赠品门槛变化
      // case 2: 加价购自身不叠加时 此时除了复原秒杀/拼团外 无其余变化 需要重新获取一次优惠券数据 根据秒杀/拼团价
      // case 3: 全部叠加时(包含在case1中) 需要考虑 加价购减少引起的赠品门槛变化
      setClearGroupAndSeckill(false);
      activityId && activityList.push({ id: activityId });
      // case 1
      if (addBuyInfo?.overlayScreen == 1) {
        activityId && activityList.push({ id: activityId });
        curCoupon.length > 0 &&
          curCoupon.map((item: any) => {
            coupons.push(item.id);
          });
        // 处理赠品
        // 过滤后的赠品展示数据
        const tempGiftInfo: any[] = [];
        if (giftInfoOrg?.length > 0) {
          let tempPrice1 = resultPrice - markupResultAmount;
          if (resultPrice - markupResultAmount < 0) {
            tempPrice1 = 0;
          }
          giftInfoOrg.map((item: any) => {
            if (
              (item.overlayScreen == 1 && item.threshold <= tempPrice1) ||
              (item.overlayScreen == 0 && item.threshold <= orderAmount)
            ) {
              tempGiftInfo.push(item);
            }
          });
        }
        setGiftInfo(tempGiftInfo);
        // 过滤后的赠品选中 (只需从过滤后的展示数据中选出已收集的)
        let tempCurGift: any[] = [];
        tempGiftInfo.length > 0 &&
          tempGiftInfo.map((item: any) => {
            if (JSON.stringify(curGiftInfo).includes(JSON.stringify(item))) {
              tempCurGift.push(item);
            }
          });
        activityListRef?.current?.clearSelect &&
          activityListRef?.current?.clearSelect();
        activityListRef?.current?.propSelect &&
          activityListRef?.current?.propSelect(tempCurGift);
        setCurGiftInfo(tempCurGift);
        tempCurGift?.length > 0 &&
          tempCurGift.map((item: any) => {
            activityList.push({
              id: item.id,
            });
          });
      } else if (addBuyInfo?.overlayScreen == 0) {
        // 价格复原成了拼团/秒杀 需要重新获取优惠券
        getCouponList(id, groupSkillPrice);
      }
      updatePriceInfo({
        commodities,
        coupons,
        activityList,
      });
    }
    setCurActInfo(activityList);
    setCurAddPriceInfo(curList);
  };
  // 选择优惠券 --考虑互斥(1.加价购本身互斥 2.赠品本身互斥 3.都互斥) --考虑门槛
  // 需要明确：点击优惠券时 需要将优惠券选择后的变化前置处理 即-不满足门槛时的数据直接清除
  // 明确 情景1: 全部活动叠加时 选择优惠券时 需根据 实付金额 + 前置优惠券金额 - 当前选中优惠
  //            过滤 赠品 加价购
  //      情景2：加价购不叠加 赠品都可叠加 点击优惠券时 无论何种情况 都将清空加价购的选择
  //            价格都将以秒杀/拼团价为基础 赠品的门槛为 秒/拼 + 前置优惠券 - 后置优惠券 + 加价
  //            而加价购清空以后 也需要根据门槛 确认是否展示 此时门槛为 非秒杀/拼团
  //      情景3： 加价购不叠加 赠品存在不可叠加 此时 清空加价购的选择
  //            价格都将以秒杀/拼团价或非为基础 加价购的门槛为 非秒杀/拼团
  //            赠品:  可叠加的 -> 门槛为 拼团/秒杀 + 前置优惠 - 当前优惠 + 加价
  //                   不可叠加的 -> 门槛为非秒杀/拼团
  //      情景4：加价购可叠加 赠品存在不可叠加 点击优惠券时 会清空不叠加的赠品的选择
  //            价格都将以秒杀/拼团价或非为基础 加价购的门槛为 秒杀/拼团 + 前置优惠 - 当前优惠
  //            赠品： 可叠加的 -> 门槛为 秒杀/拼团 + 前置优惠 - 当前优惠 + 加价
  //                  不可叠加的 -> 门槛为 非秒杀/拼团
  const selectCoupon = async (val: any) => {
    let coupons: any[] = [],
      curDiscount: number = 0,
      activityList: any[] = [],
      flagAdd = true,
      flagGift = true,
      commodities: any[] = currentSku.map((item: any) => {
        return { number: item.number, skuId: item.skuId, id };
      });
    if (val?.id) {
      coupons = [val.id];
      setCurCoupon([val]);
      if (val?.type == 0) {
        curDiscount = val.discount;
      } else {
        curDiscount =
          (resultPrice - markupResultAmount + couponResultAmount) *
          (1 - 0.1 * val.discount);
      }
    } else {
      curDiscount = 0;
      setCurCoupon([]);
    }
    // 判断叠加标识
    if (addBuyInfo?.activityId && addBuyInfo?.overlayScreen == 0) {
      flagAdd = false;
    }
    if (
      giftInfo?.length > 0 &&
      giftInfo?.some((x: any) => x.overlayScreen == 0)
    ) {
      flagGift = false;
    }
    // 全部活动可叠加时 根据优惠券 计算门槛 重新过滤赠品,加价购 -> 并根据新的活动信息 -> 重新二次计算价格
    if (flagAdd && flagGift) {
      activityId && activityList.push({ id: activityId });
      // 过滤后的赠品展示数据(此时 都可叠加)
      const tempGiftInfo: any[] = [];
      let tempPrice1 =
        resultPrice + couponResultAmount - curDiscount + markupResultAmount;
      if (tempPrice1 < 0) {
        tempPrice1 = 0;
      }
      if (giftInfoOrg?.length > 0) {
        giftInfoOrg.map((item: any) => {
          if (item.threshold <= tempPrice1) {
            tempGiftInfo.push(item);
          }
        });
      }
      setGiftInfo(tempGiftInfo);
      // 过滤后的赠品选中 (只需从过滤后的展示数据中选出已收集的)
      let tempCurGift: any[] = [];
      tempGiftInfo.length > 0 &&
        tempGiftInfo.map((item: any) => {
          if (JSON.stringify(curGiftInfo).includes(JSON.stringify(item))) {
            tempCurGift.push(item);
          }
        });
      activityListRef?.current?.clearSelect &&
        activityListRef?.current?.clearSelect();
      activityListRef?.current?.propSelect &&
        activityListRef?.current?.propSelect(tempCurGift);
      setCurGiftInfo(tempCurGift);
      tempCurGift?.length > 0 &&
        tempCurGift.map((item: any) => {
          activityList.push({
            id: item.id,
          });
        });
      let tempPrice2 = resultPrice + couponResultAmount - curDiscount;
      if (tempPrice2 < 0) {
        tempPrice2 = 0;
      }
      // 加价购
      if (addBuyInfoOrg?.threshold <= tempPrice2) {
        curAddPriceInfo?.length > 0 &&
          curAddPriceInfo.map((item: any) => {
            activityList.push({ id: addBuyInfo.activityId, ...item });
          });
        setAddBuyInfo(addBuyInfoOrg);
      } else {
        clearAddPriceSelect();
        setAddBuyInfo({});
      }
    } else if (!flagAdd && flagGift) {
      // 加价购不叠加 赠品都可叠加
      curAddPriceInfo?.length > 0 && clearAddPriceSelect();
      setClearGroupAndSeckill(false);
      activityId &&
        activityList.push({
          id: activityId,
        });
      // 处理赠品展示数据(此时都可叠加)
      const tempGiftInfo: any[] = [];
      // 折扣券的话 当前优惠金额需特殊处理
      if (val?.type == 1) {
        curDiscount = groupSkillPrice * (1 - 0.1 * val.discount);
      }
      let tempPrice1 = groupSkillPrice - curDiscount + markupResultAmount;
      if (tempPrice1 < 0) {
        tempPrice1 = 0;
      }
      if (giftInfoOrg?.length > 0) {
        giftInfoOrg.map((item: any) => {
          if (item.threshold <= tempPrice1) {
            tempGiftInfo.push(item);
          }
        });
      }
      setGiftInfo(tempGiftInfo);
      // 处理赠品选中数据
      let tempCurGift: any[] = [];
      tempGiftInfo.length > 0 &&
        tempGiftInfo.map((item: any) => {
          if (JSON.stringify(curGiftInfo).includes(JSON.stringify(item))) {
            tempCurGift.push(item);
          }
        });
      // 先清空再选中
      activityListRef?.current?.clearSelect &&
        activityListRef?.current?.clearSelect();
      activityListRef?.current?.propSelect &&
        activityListRef?.current?.propSelect(tempCurGift);
      setCurGiftInfo(tempCurGift);
      tempCurGift?.length > 0 &&
        tempCurGift.map((item: any) => {
          activityList.push({
            id: item.id,
          });
        });
      // 处理加价购
      if (addBuyInfoOrg?.threshold <= orderAmount) {
        setAddBuyInfo(addBuyInfoOrg);
      } else {
        clearAddPriceSelect();
        setAddBuyInfo({});
      }
    } else if (!flagAdd && !flagGift) {
      // 加价购不叠加 赠品存在不叠加
      clearAddPriceSelect();
      setClearGroupAndSeckill(false);
      activityId && activityList.push({ id: activityId });
      // 处理赠品 可叠加的 门槛展示 + 选中 不可叠加的 门槛展示
      const tempGiftInfo: any[] = [];
      // 折扣券的话 当前优惠金额需特殊处理
      if (val?.type == 1) {
        curDiscount = groupSkillPrice * (1 - 0.1 * val.discount);
      }
      let tempPrice1 = groupSkillPrice - curDiscount;
      if (tempPrice1 < 0) {
        tempPrice1 = 0;
      }
      if (giftInfoOrg?.length > 0) {
        giftInfoOrg.map((item: any) => {
          if (
            (item.overlayScreen == 1 && item.threshold <= tempPrice1) ||
            (item.overlayScreen == 0 && item.threshold <= orderAmount)
          ) {
            tempGiftInfo.push(item);
          }
        });
      }
      setGiftInfo(tempGiftInfo);
      let tempCurGift: any[] = [];
      tempGiftInfo.length > 0 &&
        curGiftInfo?.length > 0 &&
        tempGiftInfo.map((item: any) => {
          if (
            JSON.stringify(curGiftInfo).includes(JSON.stringify(item)) &&
            item.overlayScreen == 1
          ) {
            tempCurGift.push(item);
          }
        });
      tempCurGift?.length > 0 &&
        tempCurGift.map((item: any) => {
          activityList.push({
            id: item.id,
          });
        });
      activityListRef?.current?.clearSelect &&
        activityListRef?.current?.clearSelect();
      activityListRef?.current?.propSelect &&
        activityListRef?.current?.propSelect(tempCurGift);
      setCurGiftInfo(tempCurGift);
      // 处理加价购
      if (addBuyInfoOrg?.threshold <= orderAmount) {
        setAddBuyInfo(addBuyInfoOrg);
      } else {
        clearAddPriceSelect();
        setAddBuyInfo({});
      }
    } else if (flagAdd && !flagGift) {
      // 加价购可叠加 赠品存在不可叠加 触发互斥(清除掉不叠加的赠品,复原秒杀/拼团) 计算价格 -> 计算赠品门槛重置赠品细信息(并选中已选的可叠加的)
      setClearGroupAndSeckill(false);
      activityId && activityList.push({ id: activityId });
      // 折扣券的话 当前优惠金额需特殊处理
      if (val?.type == 1) {
        curDiscount = groupSkillPrice * (1 - 0.1 * val.discount);
      }
      let tempPrice1 = groupSkillPrice - curDiscount;
      if (tempPrice1 < 0) {
        tempPrice1 = 0;
      }
      // 处理加价购
      if (addBuyInfoOrg?.threshold <= tempPrice1) {
        curAddPriceInfo?.length > 0 &&
          curAddPriceInfo.map((item: any) => {
            activityList.push({ id: addBuyInfo.activityId, ...item });
          });
        setAddBuyInfo(addBuyInfoOrg);
      } else {
        clearAddPriceSelect();
        setAddBuyInfo({});
      }
      // 处理赠品
      // 展示信息
      let tempPrice2 = groupSkillPrice - curDiscount + markupResultAmount;
      if (tempPrice2 < 0) {
        tempPrice2 = 0;
      }
      const tempGiftInfo: any[] = [];
      if (giftInfoOrg?.length > 0) {
        giftInfoOrg.map((item: any) => {
          if (
            (item.overlayScreen == 1 && item.threshold <= tempPrice2) ||
            (item.overlayScreen == 0 && item.threshold <= orderAmount)
          ) {
            tempGiftInfo.push(item);
          }
        });
      }
      setGiftInfo(tempGiftInfo);
      // 处理赠品选中数据
      let tempCurGift: any[] = [];
      tempGiftInfo.length > 0 &&
        tempGiftInfo.map((item: any) => {
          if (
            JSON.stringify(curGiftInfo).includes(JSON.stringify(item)) &&
            item?.overlayScreen == 1
          ) {
            tempCurGift.push(item);
          }
        });
      activityListRef?.current?.clearSelect &&
        activityListRef?.current?.clearSelect();
      activityListRef?.current?.propSelect &&
        activityListRef?.current?.propSelect(tempCurGift);
      setCurGiftInfo(tempCurGift);
      tempCurGift?.length > 0 &&
        tempCurGift.map((item: any) => {
          activityList.push({
            id: item.id,
          });
        });
    }
    let params = {
      commodities,
      coupons,
      activityList,
    };
    updatePriceInfo(params);
    setCurActInfo(activityList || []);
  };
  // 是否需要展示优惠活动模块-不叠加时勾选赠品
  useEffect(() => {
    if (
      giftInfo?.length > 0 &&
      (giftInfo?.some((x: any) => x?.overlayScreen == 0) ||
        (addBuyInfo?.activityId && addBuyInfo?.overlayScreen == 0))
    ) {
      setShowActivityList(true);
    } else {
      giftInfo?.length > 0 &&
        giftInfo.map((item: any) => {
          let tempData = curActInfo;
          tempData.push({
            id: item.id,
          });
          setCurActInfo(tempData);
        });
      setShowActivityList(false);
    }
  }, [giftInfo, addBuyInfo]);
  useEffect(() => {
    if (showActivityList) {
      activityListRef?.current?.propSelect &&
        activityListRef?.current?.propSelect(curGiftInfo);
    }
  }, [showActivityList]);
  return (
    <div className="orderSettlementOutWrap">
      <div className="orderSettleMentWrap">
        <div className="page-title">订单结算</div>
        {timeList.length > 0 ? (
          <UseLimit
            timeList={timeList}
            selectList={selectList || []}
            changeSku={changeSku}
          />
        ) : (
          <OrderInfo
            infoData={selectList || []}
            contract={contract}
            signStatus={signStatus}
            fileUrl={fileUrl ? fileUrl : contract.url}
            recordId={recordId}
            commodityId={id}
            callback={goSign}
            clearGroupAndSeckill={clearGroupAndSeckill}
          />
        )}
        {showActivityList ? (
          <ActivityList
            info={giftInfo || []}
            addBuyInfo={addBuyInfo}
            getCurGiftActivity={getCurGiftActivity}
            ref={activityListRef}
          />
        ) : null}
        {giftInfo?.length > 0 &&
        !(curGiftInfo?.length == 0 && showActivityList) ? (
          <Gift
            info={giftInfo || []}
            showActivityList={showActivityList}
            curSelectGifiActivityList={curGiftInfo || []}
            fromType={"pay"}
          />
        ) : null}
        {/* 加价购模块--start  */}

        {addBuyInfo?.activityId ? (
          <AddPriceBuy
            info={addBuyInfo}
            getSelectAddPrice={getSelectAddPrice}
            ref={addPriceRef}
          />
        ) : null}
        {/* 加价购模块--end */}
        {couponList?.length > 0 ? (
          <Counp
            couponList={couponList}
            callback={selectCoupon}
            ref={couponRef}
          />
        ) : null}
        <div className="settlement-box">
          <div className="right-box">
            <div>
              <div className="price-box">
                <div className="price-level-1">
                  <div className="good-nums-box">
                    <div className="good-nums-val">{goodNums}</div>件商品,
                  </div>
                  <div className="price-show">
                    <div>商品原价:</div>
                    <div>
                      ￥
                      {Number(
                        Number(resultPrice) + Number(resultDiscount)
                      ).toFixed(2)}
                    </div>
                  </div>
                </div>
                <div className="right-box-on-padding">
                  <div>
                    <div className="price-level-2">
                      <div className="price-show">
                        <div>优惠金额:</div>
                        <div className="price-discont">
                          - ￥{resultDiscount}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right-box-on-padding">
                  <div>
                    <div className="price-level-3">
                      <div className="price-show">
                        <div className="price-real-text">实付金额:</div>
                        <div className="price-real-box">
                          {" "}
                          <div className="price-real-unit">￥</div>
                          <div className="price-real">{resultPrice}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right-box border-top">
            <div style={{ display: "flex" }}>
              {checked ? (
                <div
                  className="iconfont icon-Select_square_1_5 check-icon"
                  onClick={() => setChecked(!checked)}
                ></div>
              ) : (
                <div
                  className="iconfont icon-select_square_1_1 uncheck-icon"
                  onClick={() => setChecked(!checked)}
                ></div>
              )}
              <div className="constact-box">
                我已阅读
                {contract && contract?.id && contract?.signTime == 1 ? (
                  <a
                    className="constract-link"
                    href={`${contract?.url}`}
                    target="_blank"
                  >
                    《产品协议》
                  </a>
                ) : null}
                {contract && contract?.id && contract?.signTime == 1
                  ? "与"
                  : null}
                <a
                  className="constract-link"
                  href={`${getCDNUrl()}/protocol/${channelId}/provision.html`}
                  target="_blank"
                >
                  《学员服务条款》
                </a>
                并知晓协议内容
              </div>
            </div>
          </div>
          <div className="right-box">
            <div>
              {signStatus === 0 && contract?.signTime === 1 ? (
                <div className="pay-btn" onClick={() => goSign()}>
                  签署协议
                </div>
              ) : (
                <div className="pay-btn" onClick={() => payNow()}>
                  立即结算
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bottom-box-zhanwei"></div>
      </div>
      {contextHolder}
      {isShowPayModal ? (
        <PayModal onClose={handleClose} orderId={orderId} />
      ) : null}
    </div>
  );
}

export default OrderSettlement;
