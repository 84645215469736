/*
 *横向滑动商品组件
 */
import { Carousel, Empty } from "antd";
import "./css/scrollList.scss";
import { useEffect, useState } from "react";
interface Iprops {
  data: any;
  showMore: boolean;
  goShowMore?: any;
  changeProject?: any;
  goCourseDetail: any;
  checkMoreByPro?: Function;
  title: string;
  showNum: number;
  showByProject?: boolean;
  showTitle: boolean;
  fields: any;
}

const HorizontalScroll = (props: Iprops) => {
  const {
    goShowMore,
    showMore,
    changeProject,
    goCourseDetail,
    checkMoreByPro,
    title,
    showNum,
    showByProject,
    showTitle,
    fields,
  } = props;
  const { projectList, currentTab, courseCard } = props.data;
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScrollRight = () => {
    const element = document.getElementById("scroll-list");
    let x = element?.scrollWidth;
    if (x && x - scrollPosition > 472 * 2) {
      setScrollPosition(scrollPosition + 472);
    }
  };

  const handleScrollLeft = () => {
    if (scrollPosition === 0) {
      return;
    } else {
      setScrollPosition(scrollPosition - 472);
    }
    console.log(scrollPosition, "444444");
  };
  useEffect(() => {
    setScrollPosition(0);
  }, [courseCard]);

  return (
    <div className="page-box">
      <div className="top-all" style={{ paddingTop: 24, paddingBottom: 24 }}>
        {showTitle ? <div className="nav-title">{title}</div> : null}
        <br />
        {showByProject ? (
          <div className="exam-list fl">
            <ul>
              {projectList &&
                projectList?.length > 0 &&
                projectList.map((item: any, index: Number) => {
                  return (
                    <li
                      onClick={() => changeProject(item, index)}
                      key={item.projectId}
                      data-classNameify="free"
                      data-sign="gongcheng"
                      className={
                        currentTab === index
                          ? "nav-item nav-active"
                          : "nav-item"
                      }
                    >
                      {item.projectName}
                    </li>
                  );
                })}
            </ul>
          </div>
        ) : null}
        {showMore && showTitle ? (
          <div onClick={() => goShowMore()} style={{ color: "#333333" }}>
            查看更多{" "}
            <span className=" more-icon-01 iconfont icon-symbol_right"></span>
          </div>
        ) : null}
      </div>
      <div className="scroll-main">
        <div className="left-button" onClick={handleScrollLeft}>
          <i className="iconfont icon-symbol_left"></i>
        </div>
        <div className="right-button" onClick={handleScrollRight}>
          <i className="iconfont icon-symbol_right"></i>
        </div>
        <div
          className="scroll-list"
          id="scroll-list"
          style={{ transform: `translateX(-${scrollPosition}px)` }}
        >
          {courseCard && courseCard?.length > 0 ? (
            courseCard.map((item: any, indexS: number) => {
              {
                return showNum > indexS ? (
                  <div
                    className="card-box btn-cursor"
                    onClick={() => goCourseDetail(item)}
                  >
                    <img src={item?.thumbnail} className="left-img" />
                    <div className="right-card">
                      {fields.includes("name") ? (
                        <div className="good-name line-one">{item?.name}</div>
                      ) : null}
                      {fields.includes("sellPoint") ? (
                        <div className="right-card-sellingPoint">
                          {item?.sellingPoint}
                        </div>
                      ) : null}
                      <div className="teacher-all">
                        {fields.includes("teachers") ? (
                          <div className="recommend-teacher-box">
                            {item?.teachers?.length > 0 ? (
                              <div style={{ display: "flex" }}>
                                {item.teachers.map(
                                  (child: any, index: number) => {
                                    if (index < 3) {
                                      return (
                                        <div className="teacher-one-recommed">
                                          <img
                                            src={child.photo}
                                            className="teacher-img-l-recommed"
                                          />
                                          <div className="teacher-name-l-recommed">
                                            {child.name}
                                          </div>
                                        </div>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                        {fields.includes("price") ? (
                          <div className="price-recommed-box">
                            <span className="recommed-price-icon">￥</span>
                            <span className="recommed-price-data">
                              {item?.price}
                            </span>
                            <span className="recommed-price-text">起</span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null;
              }
            })
          ) : (
            <Empty
              description="暂无数据"
              style={{
                minHeight: 300,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default HorizontalScroll;
