/* eslint-disable  */
import React, { useState, useEffect } from "react";
import "../css/groupInvite.scss";
import userStore from "@/store/user";
import QrcodeModal from "@/components/QrcodeModal";
function GroupInvite(props: any) {
  const { groupInfo, commodityId, orderId,goBackHome } = props;
  const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false); // h5邀请弹窗
  const [userInfo, setUserInfo] = useState(userStore.getUserInfo());
  const [showGroupInfo, setShowGroupInfo] = useState<any>({});
  const [remain, setRemain] = useState(0);
  const [timeData, setTimeData] = useState({
    hour: 0,
    min: 0,
    second: 0,
  });
  useEffect(() => {
    setUserInfo(userStore.getUserInfo());
  }, [userStore.userInfo]);
  // 关闭弹框
  const handleCloseQrCode = (val: boolean) => {
    setQrCodeModalOpen(val);
  };
  useEffect(() => {
    if (showGroupInfo?.inDate) {
      setRemain(showGroupInfo.inDate);
    }
  }, [showGroupInfo?.inDate]);
  useEffect(() => {
    if (remain) {
      reset();
    }
  }, [remain]);
  // 时间补全 0
  const checkTime = (i: any) => {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  };
  const updateTimeData = (t: any) => {
    let hour = Math.floor(t / 1000 / 60 / 60);
    let minute = Math.floor((t / 1000 / 60) % 60);
    let second = Math.floor((t / 1000) % 60);
    setTimeData({
      hour: checkTime(hour),
      min: checkTime(minute),
      second: checkTime(second),
    });
  };

  let timer: any = null;
  // 开启倒计时
  const startTimer = () => {
    if (timer) {
      clearInterval(timer);
    }
    if (remain < 1000) {
      return;
    }
    let timeDown = remain;
    timer = setInterval(() => {
      timeDown -= 1000;
      updateTimeData(timeDown);
      if (timeDown < 1000) {
        clearInterval(timer);
      }
    }, 1000);
  };

  // 重置倒计时
  const reset = () => {
    updateTimeData(remain);
    start();
  };

  // 暂停倒计时
  const pause = () => {
    if (timer) {
      clearInterval(timer);
      timer = null;
    }
  };

  // 开始倒计时
  const start = () => {
    if (timer) {
      return;
    }
    startTimer();
  };
  useEffect(() => {
    if (groupInfo?.userInfos?.length > 0) {
      let imgList: any[] = [];
      groupInfo.userInfos.map((item: any) => {
        imgList.push(item);
      });
      for (let i = 0; i < groupInfo.lackNum; i++) {
        imgList.push({
          avatar: "",
        });
      }
      console.log(imgList, "imgList");

      setShowGroupInfo({ ...groupInfo, imgList });
    }
  }, [groupInfo]);
  
  return (
    <div className="group-invite-box">
      <div className="invite-text flex">
        还差 <div className="invite-text-blue">{groupInfo.lackNum}</div> 人，
        <div className="invite-time-box flex">
          <div className="invite-time-box-one">{timeData?.hour}</div>
          <div className="invite-time-box-one-icon">:</div>
          <div className="invite-time-box-one">{timeData?.min}</div>
          <div className="invite-time-box-one-icon">:</div>
          <div className="invite-time-box-one">{timeData?.second}</div>
        </div>
        后结束
      </div>
      <div className="invite-text invite-second">
        拼团还未成功,让小伙伴们都来拼团吧~
      </div>
      <div className="invite-user-box flex">
        <div
          className="invite-user-img"
          style={{
            background: `url("${userInfo.avatar}") no-repeat`,
            backgroundSize: "100% 100%",
          }}
        >
          <div className="group-owner">拼主</div>
        </div>
        {groupInfo.lackNum > 4 ? (
          <div className="flex">
            <div
              className="invite-user-img-other left-24"
              onClick={() => setQrCodeModalOpen(true)}
              style={{
                background: showGroupInfo?.imgList[1]?.avatar
                  ? `url("${showGroupInfo?.imgList[1]?.avatar}") no-repeat`
                  : "",
                backgroundSize: "100% 100%",
              }}
            ></div>
            <div
              className="invite-user-img-other left-24"
              onClick={() => setQrCodeModalOpen(true)}
              style={{
                background: showGroupInfo?.imgList[2]?.avatar
                  ? `url("${showGroupInfo?.imgList[2]?.avatar}") no-repeat`
                  : "",
                backgroundSize: "100% 100%",
              }}
            ></div>
            <div className="invite-center-icon">. . .</div>
            <div
              className="invite-user-img-other-last"
              onClick={() => setQrCodeModalOpen(true)}
              style={{
                background: showGroupInfo?.imgList[2]?.avatar
                  ? `url("${showGroupInfo?.imgList[2]?.avatar}") no-repeat`
                  : "",
                backgroundSize: "100% 100%",
              }}
            ></div>
          </div>
        ) : (
          showGroupInfo?.imgList?.length > 0 &&
          showGroupInfo?.imgList.map((item: any, index: any) => {
            if (index != 0) {
              return (
                <div
                  className={
                    index != showGroupInfo?.imgList.length - 1
                      ? "invite-user-img-other left-32"
                      : "invite-user-img-other-last left-32"
                  }
                  style={{
                    background: item.avatar
                      ? `url("${
                          item.avatar || "../imgs/group-user-one.png"
                        }") no-repeat`
                      : "",
                    backgroundSize: "100% 100%",
                  }}
                  onClick={() => setQrCodeModalOpen(true)}
                  key={index}
                ></div>

                // </div>
              );
            }
          })
        )}
      </div>
      <div className="invite-btn-box flex">
        <div
          className="invite-btn-left"
          onClick={() => setQrCodeModalOpen(true)}
        >
          邀请好友拼单
        </div>
        <div className="invite-btn-right" onClick={() => goBackHome()}>去首页</div>
      </div>
      <QrcodeModal
        isShow={qrCodeModalOpen}
        type={"group"}
        onCloseQrCode={handleCloseQrCode}
        groupUrl={`/page_commodity/order/pages/collageDetail?id=${orderId}&commodityId=${commodityId}`}
      />
    </div>
  );
}

export default GroupInvite;
