/* eslint-disable  */
import React, { useState, useEffect } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import RecursionLevelList from "@/components/CourseTree/RecursionLevelList";
import "../css/videoLeft.scss";
import { fetchGetStudyStat } from "@/apis/courseDetail";
import { flatList, getProductIds } from "@/utils/tree";
function VideoLeft(props: any) {
  const [isOpen, setIsOpen] = useState(true);
  const { productTrees } = props.courseData;
  const [list, setList] = useState([]);
  const [statics, setStatics] = useState<any[]>([]);
  const [isPurchase, setIsPurchase] = useState(false);
  const { callback, unitType, unitId, pdf, courseData } = props;
  const initListData = async () => {
    if (!productTrees) {
      return;
    }

    // 利用缓存重新组织树结构
    const productIds = getProductIds(productTrees, "P");
    const statics1 = await fetchGetStudyStat({ productIds });
    const originList: any = flatList(productTrees, 1);
    setStatics(statics1);
    setList(originList);
    statics1.map((item: any) => {
      findPruchase(item);
    });
  };
  const findPruchase = (next: any) => {
    const find = (next: any) => {
      if (next.isPurchase) {
        setIsPurchase(true);
        return true;
      }
    };
    return !!find(next);
  };
  const pdfDown = () => {
    const str = decodeURIComponent(pdf);
    let startIndex = str.indexOf("material/");
    const name = str.substring(startIndex + 9);
    console.log(pdf, name, "pdf");
    downloadFile(pdf, name, "pdf");
  };
  const downloadFile = (url: string, fileName: string, fileType: string) => {
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": `application/${fileType}`, //配置下载类型 fileType比如pdf
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);
      });
  };
  useEffect(() => {
    initListData();
  }, [props.courseData.productTrees]);
  return (
    <div className="video-left-box">
      {/* 结构树 */}
      {isOpen ? (
        <div className="video-tree">
          <RecursionLevelList
            list={(list || []) as any}
            statics={statics}
            callback={callback}
            isHideProgress={false}
            allExpan={true}
            importType={1}
            curUnitId={unitId}
          />
        </div>
      ) : null}
      {/* 目录 */}
      <div className="video-left-menu">
        <div
          className={
            isOpen ? "directory-box directory-box-active" : "directory-box"
          }
          onClick={() => {
            if (isOpen) {
              setIsOpen(false);
            } else {
              setIsOpen(true);
            }
          }}
        >
          <div className="iconfont icon-directory"></div>
          <div>目录</div>
        </div>
        {pdf ? (
          unitType != 0 ? (
            <div className="handout-box" onClick={pdfDown}>
              <div className="iconfont icon-handout"></div>
              <div>
                下载<br></br>讲义
              </div>
            </div>
          ) : isPurchase ? (
            <div className="handout-box" onClick={pdfDown}>
              <div className="iconfont icon-information"></div>
              <div>
                下载<br></br>资料
              </div>
            </div>
          ) : null
        ) : null}
      </div>
      {/* 抽屉触发 */}
      <div
        className="video-left-change"
        onClick={() => {
          if (isOpen) {
            setIsOpen(false);
          } else {
            setIsOpen(true);
          }
        }}
      >
        {isOpen ? (
          <RightOutlined></RightOutlined>
        ) : (
          <LeftOutlined></LeftOutlined>
        )}
      </div>
    </div>
  );
}
export default VideoLeft;
